<template>
    <div class="qr-view">
        <!--<ErroMessage v-if="apiErrors.isError"
                 :error-types="landingPageConst.errors"
                 :texts="errorText"
                 :is-error="true"
                 :err-code="apiErrors.errCode"
                 :is-client-err="apiErrors.isClientErr"
                 @err-back-click="errBackClick">
    </ErroMessage>-->

        <div class="content">
            <!-- <SuccessExitOverlay
      class="success_pay_con"
      v-if="isStep(steps.paymentSuccess)"
      img="//static.nayax.com/machine_Landing_page_asstes/success4.gif"
      :timeToExit="timeToExit"
  /> -->
            <!-- <PaymentSession /> -->
            <Ticket />
            <PaymentSection>
                <CreditCardForm
                    :is-pay-proccess="isPayProccess"
                    @creditcard-submit="PayCC"
                    ref="card_component"
                >
                </CreditCardForm>
            </PaymentSection>
        </div>
    </div>
</template>

<script setup>
    import useQrStore from '../../store/qr';
    import usePaymentSessionStore from '../../store/paymentSession';
    import CreditCardForm from '../../components/common/CreditCardForm';
    import PaymentSection from '../../components/Qr/PaymentSection.vue';
    //import SuccessExitOverlay from '../../components/Scan2Pay/SuccessExitOverlay.vue';
    import Ticket from '../../components/Scan2Pay/Ticket.vue';
    import { ref, computed } from 'vue';
    import { formatTime } from '../../utils/formatters';
    import ApiClient from '../../utils/apiClient';
    import { useRouter } from 'vue-router';
    import { RsaEncrypt } from '@/utils/encryptionUtils';
    import { CREDIT_CARD_PAYMENT_METHOD } from '@/constants';
    const router = useRouter();

    const qrStore = useQrStore();
    const paymentSessionStore = usePaymentSessionStore();

    const steps = {
        init: 0,
        start: 1,
        creditCard: 2,
        googlePay: 3,
        applePay: 4,
        paymentFail: 5,
        paymentSuccess: 6,
        rating: 6,
        ereceipt: 7,
        finish: 8,
    };

    const publicKeyFromPem = computed(() => qrStore.publicKeyFromPem);

    function PayCC(card) {
        isPayProccess.value = true;
        var cc = {
            number: card.cardNumber,
            name: card.cardName,
            expire: card.cardExpire,
            cvv: card.cardCvv,
        };
        const payload =
            publicKeyFromPem.value === null ? '' : RsaEncrypt(publicKeyFromPem.value, cc);
        var json = {
            QR: token.value,
            Payload: payload,
        };

        ApiClient.Scan2Pay.apm(json, CREDIT_CARD_PAYMENT_METHOD)
            .then((result) => {
                if (result.data.isSuccess) {
                    qrStore.setStep(steps.paymentSuccess);

                    setTimeout(() => {
                        const paymentSession = {
                            mode: 'Stop',
                            time: 0,
                        };
                        paymentSessionStore.setPaymentSession(paymentSession);
                        window.sessionStorage.setItem('localTimeToExitStr', result.data.localTimeToExitStr);
                      
                        router.push({
                            name: 'Receipt',
                            params: {
                                transaction: token.value,
                            },
                        });
                    }, 3000);
                } else {
                    processPaymentError('Payment was not approved');
                }
            })
            .catch(function (ex) {
                processPaymentError(ex);
            });
    }

    function processPaymentError(error) {
        isPayProccess.value = false;
        const paymentSession = {
            mode: 'Stop',
            time: 0,
        };
        paymentSessionStore.setPaymentSession(paymentSession);
        errorHandler(error);
    }

    function errorHandler(err) {
        //console.log('errorHandler:' + JSON.stringify(err));
        router.push({
            name: 'error',
            params: {
                type: 'payment',
            },
        });
    }

    function formatToTime(date) {
        return formatTime(date);
    }
    //refs
    const isPayProccess = ref(false);

    //computed
    const token = computed(() => qrStore.token);
</script>

<style>
    .apple-pay-button {
        display: inline-block;
        -webkit-appearance: -apple-pay-button;
    }

    .apple-pay-button-black {
        -apple-pay-button-style: black;
    }

    .apple-pay-button-white {
        -apple-pay-button-style: white;
    }

    .apple-pay-button-white-with-line {
        -apple-pay-button-style: white-outline;
    }

    .security-icons {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        display: flex;
        margin-top: 30px;
        align-items: center;
        justify-content: space-evenly;
        margin-bottom: 10px;
    }

    .security-icons img {
        padding: 6px;
        width: 75px;
        image-rendering: pixelated;
    }

    .bbtn {
        box-sizing: border-box;
        line-height: inherit;
        overflow: visible;
        -webkit-appearance: button;
        height: 50px;
        background: #e0e0e0;
        border-radius: 30px;
        background-color: white;
        border: 1px solid #262626;
        color: #262626;
        font-size: 0.9rem;
        transition: all 0.5s ease-in;
        opacity: 1;
        width: 315px;
        margin: 30px auto 15px;
        position: relative;
        text-transform: uppercase;
        font-weight: 600;
        cursor: pointer;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
</style>
