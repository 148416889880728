import defaultTranslations from './defaultTranslations';

type StringDictionary = { [key: string]: string };

const buildDictionary = () => {
    const languages = require.context('./languages', false, /\.js$/);
    const dictionary: { [key: string]: StringDictionary } = {};
    const defaultTranslationsConverted = keysToLowerCase(defaultTranslations);

    languages.keys().forEach((key) => {
        const module = languages(key);
        const extension = key.lastIndexOf('.');
        const start = key.indexOf('/') + 1;
        const actualKey = key.substring(start, extension);

        const merged = { ...keysToLowerCase(module.default), ...defaultTranslationsConverted };

        dictionary[actualKey] = merged;
    });

    return dictionary;
};

const keysToLowerCase = (obj: StringDictionary) => {
    const entries = Object.entries(obj);
    const clone = { ...obj };

    for (let index = 0; index < entries.length; index++) {
        const [key, value] = entries[index];
        const newKey = key.toLowerCase();
        if (newKey !== key) {
            clone[newKey] = value;
            delete clone[key];
        }
    }
    return clone;
};

const dictionary = buildDictionary();

export const translate = (text: string, language: string | null) => {
    if (!text || !language) return text;

    const toCheck = text.toLowerCase();
    let current = dictionary[language];

    if (!current) {
        current = defaultTranslations;
    }
    return current[toCheck] ?? text;
};
