export function isValidTZ(input) {
    // passport
    if (new RegExp('^[A-Z][0-9]{8}$').test(input)) return true;

    // tz
    var id = String(input).trim();
    if (id.length > 9 || id.length < 5 || isNaN(id)) return false;

    // Pad string with zeros up to 9 digits
    id = id.length < 9 ? ('00000000' + id).slice(-9) : id;

    return (
        Array.from(id, Number).reduce((counter, digit, i) => {
            const step = digit * ((i % 2) + 1);
            return counter + (step > 9 ? step - 9 : step);
        }) %
            10 ===
        0
    );
}

export function isValidExp(v) {
    v = v.replace('/', '');
    if (v.length !== 4) return false;

    var month = v.substr(0, 2);
    var parsedMonth = parseInt(month);
    if (parsedMonth <= 0) return false;
    if (parsedMonth >= 13) return false;

    var year = v.substr(2, 2);
    var parsedYear = parseInt(year);
    if (parsedYear < new Date().getFullYear() - 2000) return false;

    var today = new Date();
    var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

    var someday = new Date();
    someday.setFullYear(2000 + parsedYear, parsedMonth - 1, 1);
    if (someday < firstDay) {
        return false;
    }

    return true;
}

export function isNumbers(value) {
    for (var i = 0; i < value.length; i++) {
        switch (value[i]) {
            case '0':
            case '1':
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9':
                continue;
            default:
                return false;
        }
    }
    return true;
}

export function isValidCvv(v) {
    var isnum = isNumbers(v);
    return (isnum && v.length === 3) || v.length === 4;
}

export function isValidCard(v) {
    v = v.replace(/ /g, '');
    if (isracardCheck(v)) return true;

    return v.length >= 11 && v.length <= 19 && checkLuhn(v);
}

export function checkLuhn(card) {
    var s = 0;
    var doubleDigit = false;
    for (var i = card.length - 1; i >= 0; i--) {
        var digit = +card[i];
        if (doubleDigit) {
            digit *= 2;
            if (digit > 9) digit -= 9;
        }
        s += digit;
        doubleDigit = !doubleDigit;
    }
    return s % 10 === 0;
}

export function isracardCheck(num) {
    // algorithm explanation: https://web.archive.org/web/20140227235803/http://povolotski.me/2013/09/24/isracard-credit-card-number-validation-2/
    if (typeof num !== 'number') num = '' + num;
    if (num.length < 8 || num.length > 9) return false;
    var sum = 0;
    num.split('').forEach(function (val, key) {
        sum += parseInt(val, 10) * (num.length - key);
    });
    return sum % 11 === 0;
}
