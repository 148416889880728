<template>
    <div class="main">
        <div class="wrapper">
            <img src="//static.nayax.com/machine_Landing_page_asstes/sending.gif" />
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            img: String,
        },
    };
</script>
<style scoped>
    .overlay {
        /* Height & width depends on how you want to reveal the overlay (see JS below) */
        height: 100%;
        width: 0;
        position: fixed; /* Stay in place */
        z-index: 99999; /* Sit on top */
        left: 0;
        top: 0;
        background-color: rgb(0, 0, 0); /* Black fallback color */
        background-color: rgba(255, 255, 255, 0.8); /* white w/opacity */
        overflow-x: hidden; /* Disable horizontal scroll */
        transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
        height: 100%;
    }
    .overlay-content {
        position: relative;
        top: 25%; /* 25% from the top */
        width: 100%; /* 100% width */
        text-align: center; /* Centered text/links */
        margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
    }

    .main {
        text-align: left;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 99999;
        overflow: auto;
    }
    .wrapper {
        box-sizing: border-box;
        position: absolute;
        right: 0;
        left: 0;
        margin: 0 auto;
        top: 25%;
        justify-content: center;
        display: flex;
        animation-name: fade_in;
        animation-duration: 1s;
    }

    @media (max-width: 500px) {
        .wrapper {
            top: 40%;
        }
    }
</style>
