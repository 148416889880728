<template>
    <CookieConsent
        :message="cookieMessageTxt"
        :button-label="cookieBtnTxt"
        :link-label="cookieLinkLabelTxt"
        href="https://www.nayax.com/cookie-policy/"
    />
</template>

<script>
    import CookieConsent from 'vue-cookieconsent-component';

    export default {
        components: {
            CookieConsent,
        },
        data() {
            return {
                cookieMessageTxt: this.$t(
                    'We use the necessary cookies to make the site work and for security purposes. For more details: '
                ),
                cookieBtnTxt: this.$t('I understand'),
                cookieLinkLabelTxt: this.$t('privacy policy'),
            };
        },
    };
</script>
<style>
    .cookie-consent {
        display: flex;
        padding: 10px;
        align-items: right;
        align-self: center;
        justify-content: center;
        border-bottom: 1px solid white;
        color: #262626;
        height: 60px;
        font-size: 13px;
        font-weight: 400;
        line-height: 14px;
        position: sticky;
        bottom: 0;
        background-color: #fff;
        text-align: initial;
    }

    .cookie-consent-message {
        top: 1px;
        right: 12%;
        left: 12%;
    }

    .cookie-consent button {
        border: 1px solid white;
        padding: 10px;
        position: relative;
        background-color: #007aff;
        color: #fff;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 10px;
        text-align: center;
        width: 110px;
    }

    .unset {
        display: unset;
    }

    @media (max-width: 500px) {
        .cookie-consent {
            font-size: 11px;
        }

        .cookie-consent button {
            font-size: 13px;
        }
    }
</style>
