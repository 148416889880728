import { Steps } from '@/constants/landingPage';
import { TransactionInfoApiModel } from '@/typings/apiClient/common';
import { PaymentMethodInformation } from '@/typings/paymentMethods';
import { defineStore } from 'pinia';

interface MachineState {
    machineId: number | null;
    machinePic: string;
    machinePicture: string;
    machineName: string;
    machineNumber: string;
    rating: number;
    currencySign: string;
    distImage: string;
    machineTypeLutCkey: string;
    machineTypeLutImage: string;
    products: MachineProduct[];
}

interface MachineProduct {
    price: number;
    name: string;
    image: string;
    selectionId: any;
}
interface State {
    loading: boolean;
    merchantId: string | null;
    paymentMethodInformation: PaymentMethodInformation | null;
    step: number;
    countryCode: string;
    currencyCode: string;
    totalPrice: number;
    publicKeyFromPem: string | null;
    token: string | null;
    timeToExit: number | null;
    machine: MachineState;
}
interface Actions {
    setStep: (step: Steps) => void;
    setPaymentData: (transactionInfo: TransactionInfoApiModel) => void;
}

export default defineStore<'qrStore', State, {}, Actions>({
    state: () => ({
        machine: {
            machineId: null,
            machinePic: '',
            machinePicture: '',
            machineName: '',
            machineNumber: '',
            rating: 0.0,
            currencySign: '',
            distImage: '',
            machineTypeLutCkey: '',
            machineTypeLutImage: '',
            products: [
                {
                    price: 0.0,
                    name: '',
                    image: '',
                    selectionId: null,
                },
            ],
        },
        loading: true,
        merchantId: null,
        paymentMethodInformation: null,
        step: 0,
        countryCode: 'US',
        currencyCode: 'USD',
        totalPrice: 0.0,
        publicKeyFromPem: null,
        token: null,
        timeToExit: null,
    }),
    actions: {
        setStep(step: Steps) {
            this.step = step;
        },
        setPaymentData(transactionInfo: TransactionInfoApiModel) {
            // var transactionInfo = { "id": 1, "publicKey": "-----BEGIN PUBLIC KEY-----\r\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwRj1GxW9fGnx/ycDDRzq\r\nyZ0ws/myRNWTcqDLQ9CSdp2Xold/oMUpempus9y8gmLIBsEpKoT/F3Q/bvtXK6HC\r\n/LsqjJGRKmgo2gDmeVbG8vmjLVGc+8RhD4DPlZzYbjtzzo5vFTG0BgWp5UMsWV2K\r\n/7ltGoSD5nDdlmC+obVILPKo+XeYHf1OLs8ps0IhjPmGhMcjGiLq3QNnsEm0SwAZ\r\n+2nNXUnXlxlc1TdxDozYFDefDQVKTqUajDy3kbfmTqUfluGHGchvbfMnzokqrkT2\r\nsJ+afMclAhxZkJXzyr/NaClzM9/5bJcCyvG3CghRh6hBPKMAobBhh7MA2jGOrDpm\r\nJQIDAQAB\r\n-----END PUBLIC KEY-----", "totalPrice": 0.0000, "paymentChannels": ["applepayecom", "googlepayecom", "credit_card"], "machineId": 111376192, "machinePic": "https://s3.amazonaws.com/qa.nayax.com/machinePictures/27594/2001903702/111376192/20210721115735_111376192_image.jpg", "machineName": "Landing Page", "machineNumber": "", "rating": 5.00, "currencySign": "€", "distImage": "https://static.nayax.com/res/media/201608291333139470/dcs_demo-logo_option_2.png", "machineTypeLutCkey": "machine_type_air", "machineTypeLutImage": "https://s3.amazonaws.com/qa.nayax.com/res/media/202107221128321697/machine_type_air.png", "products": [{ "name": null, "image": null, "selectionId": 0, "price": null, "unitOfMeasurement": null, "measurement": null }], "currencyCode": "EUR", "countryCode": "IL" }
            const self = this;
            self.publicKeyFromPem = transactionInfo.publicKey;
            self.totalPrice = transactionInfo.totalPrice;
            self.machine.machineId = transactionInfo.machineId;
            self.machine.machinePicture = transactionInfo.machinePic;
            self.machine.machineName = transactionInfo.machineName;
            self.machine.machineNumber = transactionInfo.machineNumber;
            self.machine.rating = transactionInfo.rating;
            self.machine.products = transactionInfo.products;
            self.machine.currencySign = transactionInfo.currencySign;
            self.machine.distImage = transactionInfo.distImage;
            self.machine.machineTypeLutCkey = transactionInfo.machineTypeLutCkey;
            self.machine.machineTypeLutImage = transactionInfo.machineTypeLutImage;
            self.paymentMethodInformation = transactionInfo.paymentMethodInformation;
            self.merchantId = transactionInfo.merchantId;
            //if (transactionInfo.currencyCode && transactionInfo.currencyCode > 1) {
            self.currencyCode = transactionInfo.currencyCode;
            //}
            if (transactionInfo.countryCode && transactionInfo.countryCode.length > 1) {
                self.countryCode = transactionInfo.countryCode;
            }
            self.token = transactionInfo.token;
        },
    },
    id: 'qrStore',
});
