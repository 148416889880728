<template>
    <div class="sessionBox sticky-top">
        <span class="sessionText">
            {{ $t('Session Time Expired') }}
        </span>
    </div>
</template>
<style scoped>
    .sessionBox {
        background-color: #aa0000;
        height: 50px;
    }

    .sessionText {
        padding-top: 16px;

        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        /* or 167% */

        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        letter-spacing: 0.16px;
        font-feature-settings: 'pnum' on, 'lnum' on;

        color: #ffffff;
    }
</style>
