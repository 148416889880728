<template>
  <div v-if="paymentError" style="display: block; margin-bottom: 60px">
    <img class="error-img" src="../../assets/images/scan2pay/payment_error.png" />

    <label class="error-label">
      {{
                $t(
                    'We seem to be having some issues with the payment method you chose. Please try again.'
                )
      }}
    </label>
    <button
            class="bbtn payment-btn btn refresh-btn"
            @click="refreshClick()"
            style="border: none"
            >
      <h4 class="refresh-btn-txt">{{ $t('refresh') }}</h4>
    </button>
  </div>
  <div v-if="recaptchaError" style="display: block; margin-bottom: 60px">
    <img class="error-img" src="../../assets/images/scan2pay/server_error.png" />

    <label class="error-label">
      {{
                $t(
                    'Captcha is not valid.'
                )
      }}
    </label>
  </div>
  <div v-if="!paymentError&&!recaptchaError" style="display: block; margin-bottom: 60px">
    <img class="error-img" src="../../assets/images/scan2pay/server_error.png" />

    <label class="error-label">
      {{
                $t(
                    'Oops! We are experiencing some technical difficulties. Please proceed to a terminal.'
                )
      }}
    </label>
  </div>
</template>

<script setup>
    import { useRouter } from 'vue-router';
    import { useRoute } from 'vue-router';
    import useScan2payStore from '../../store/scan2pay';

    let scan2payStore = useScan2payStore();
    const router = useRouter();
    const route = useRoute();
    const paymentError = route.params.type === 'payment';
  const recaptchaError = route.params.type === 'recaptcha';

    function refreshClick() {
        const qrCode = scan2payStore.scan2payInfo.qrCode;
        router.push({
            name: 'Search',
            params: { staticQr: qrCode },
        });
    }
</script>

<style scoped>
    .error-label {
        height: 116px;
        width: 307px;
        color: #000000;
        font-weight: 600;
        display: block;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    [dir='rtl'] .error-label {
        width: 180px;
    }

    .error-img {
        margin-top: 100px;
        margin-bottom: 100px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .refresh-btn {
        background-color: #ffcd00;
        display: block;
        margin-left: 0;
        border-radius: 48px;
        margin-left: auto;
        margin-right: auto;
    }
    .refresh-btn-txt {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        text-transform: uppercase;
        color: #000000;
    }
</style>
