<template>
    <div class="inner_wrap">
        <!-- error msg main container -->
        <ErrorMessage
            :is-error="apiErrors.isError"
            :err-code="apiErrors.errCode"
            :is-client-err="apiErrors.isClientErr"
        />
    </div>
</template>
<script>
    import ErrorMessage from '../../components/common/ErrorMessage.vue';

    export default {
        data() {
            return {
                componentKey: 0,
                apiErrors: {
                    isError: false,
                    errCode: -1,
                    isClientErr: false,
                },
            };
        },
        components: {
            ErrorMessage,
        },
        mounted() {
            var self = this;
            var errCode = self.$route.params.errorCode;
            self.apiErrors.isError = !!errCode;
            self.apiErrors.errCode = errCode;
            self.apiErrors.isClientErr = false;

            self.componentKey++;
        },
    };
</script>
