<template>
    <div v-if="isReady">
        <PaymentSession />
        <router-view />
        <Row v-if="!hideSecurityIcons">
            <SecurityIcons />
        </Row>
        <div class="unset">
            <CookieBox />
        </div>
        <AppFooter
            v-if="!hideFooter"
            :terms-and-conditions="termsAndConditionsUrl"
            :privacy-policy="privacyPolicyUrl"
            :contact-us="contactUs"
        />
    </div>
</template>

<script setup>
    import Row from '../../components/common/RowSection.vue';
    import AppFooter from '../../components/Scan2Pay/AppFooter.vue';
    import CookieBox from '../../components/Scan2Pay/CookieBox.vue';
    import { useRouter, useRoute } from 'vue-router';
    import { ref } from 'vue';
    import PaymentSession from '../../components/Scan2Pay/PaymentSession.vue';
    import ApiClient from '../../utils/apiClient';
    import useStore from '../../store';
    import useScan2PayStore from '../../store/scan2pay';
    import SecurityIcons from '@/components/Qr/SecurityIcons.vue';

    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const scan2PayStore = useScan2PayStore();
    const hideSecurityIcons = ref(false);
    const hideFooter = ref(false);
    const isReady = ref(false);

    store.setLoading(true);

    router.beforeEach((to, from) => {
        if (to.meta.hideSecurityIcons) {
            hideSecurityIcons.value = to.meta.hideSecurityIcons;
        }
        if (to.meta.hideFooter) {
            hideFooter.value = to.meta.hideFooter;
        }

        console.log('router' + to.meta.showSecurityIcons);
    });
    const staticQr = route.params.staticQr ?? window.sessionStorage.getItem('staticQr');
    const contactUs = ref('');
    const termsAndConditionsUrl = ref('');
    const privacyPolicyUrl = ref('');

    ApiClient.Scan2Pay.getInitialData(staticQr).then(function (response) {
        const data = response.data;
        scan2PayStore.setAppInitialInfo(data);
        store.setLanguage(response.data);
        contactUs.value = response.data.contactUsUrl ?? '';
        termsAndConditionsUrl.value = response.data.termsAndConditionsUrl;
        privacyPolicyUrl.value = response.data.privacyPolicyUrl;
        isReady.value = true;
        store.setLoading(false);
    });
</script>

<style></style>
