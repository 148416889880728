<template>
    <div class="overlay">
        <div class="overlay__inner">
            <div class="overlay__content">
                <div class="loader"></div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {};
</script>
<style scoped>
    .overlay {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        background: #222;
        /*background: rgb(34 34 34 / 50%);*/
    }

    .overlay__inner {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .overlay__content {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }
</style>
