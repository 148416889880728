import { isObjKey } from '@/utils/tsUtils';

export interface PaymentProcessingOptions {
    onLoaded?: () => boolean;
    onSuccess?: (a: any) => Promise<any>;
    onFailure?: (a: any) => void;
    gateway?: PaymentMethodProviders;
    configuration: any;
}

export enum PaymentMethodTypes {
    GOOGLE_PAYMENT_METHOD = 'gPay',
    APPLE_PAYMENT_METHOD = 'applePay',
    CREDIT_CARD_PAYMENT_METHOD = 'ccPay',
}
export interface PaymentMethodInformation {
    [PaymentMethodTypes.APPLE_PAYMENT_METHOD]: ApplePayConfiguration;
    [PaymentMethodTypes.GOOGLE_PAYMENT_METHOD]: GPayConfiguration;
    [PaymentMethodTypes.CREDIT_CARD_PAYMENT_METHOD]: {};
}

export enum PaymentMethodProviders {
    GOOGLE_PAYMENT_METHOD = 'googlepayecom',
    APPLE_PAYMENT_METHOD = 'applepayecom',
    CREDIT_CARD_PAYMENT_METHOD = 'credit_card',
    GOOGLE_PAYMENT_METHOD_CHASE = 'googlepay_via_chase',
    APPLE_PAYMENT_METHOD_CHASE = 'applepay_via_chase',
    GOOGLEPAY_VIA_CORIUNDER = 'googlepay_via_coriunder',
    APPLEPAY_VIA_CORIUNDER = 'applepay_via_coriunder',
}
export interface ApplePayConfiguration extends BasePayConfiguration {
    merchantCapabilities: ApplePayJS.ApplePayMerchantCapability[];
    supportedNetworks: string[];
}
export interface BasePayConfiguration {
    providerName: PaymentMethodProviders;
}

export interface GPayConfiguration extends BasePayConfiguration {
    allowedPaymentMethods: google.payments.api.CardAuthMethod[];
    allowedCardNetworks: google.payments.api.CardNetwork[];
    environment: google.payments.api.Environment;
    merchantId: string;
    merchantName: string;
    gatewayMerchantId: string;
    gateway?: string;
    publicKey?: string;
}
