import { Scan2PayInitialData } from '@/typings/apiClient/scan2Pay';
import { defineStore } from 'pinia';

interface Scan2PayState {
    currentLanguage: string;
    qrCode: string;
    ticketNum: number | null;
    paymentDetails: any;
    paymentSession: any;
    machineInfo: null | {
        machinePic: string;
        machineLocation: string;
    };
    machineId: null | number;
    parkingIdentifiers: string | null;
    captchaSiteKey: string | null;
     sessionCaptchaKey: string | null;
}

export default defineStore<'scan2payStore', Scan2PayState>('scan2payStore', {
    state: () => ({
        currentLanguage: 'eng',
        qrCode: '',
        ticketNum: null,
        paymentDetails: null,
        paymentSession: null,
        machineInfo: null,
        machineId: null,
        parkingIdentifiers: null,
        captchaSiteKey: null,
        sessionCaptchaKey: null,
    }),
    actions: {
        setScan2pay(value: any) {
            this.qrCode = value.qrCode;
            this.ticketNum = value.ticketNum;
            this.paymentDetails = value.paymentDetails;
            this.paymentSession = value.paymentSession;
        },
        setAppInitialInfo(data: Scan2PayInitialData) {
            this.machineInfo = {
                machinePic: data.machinePic,
                machineLocation: data.machineLocation,
            };
            this.machineId = data.machineId;
            if (data.parkingIdentifiers) this.parkingIdentifiers = data.parkingIdentifiers;
            this.captchaSiteKey = data.captchaSiteKey;
            this.sessionCaptchaKey = data.sessionCaptchaKey;
        },
    },
    getters: {
        scan2payInfo(state: any) {
            return {
                qrCode: state.qrCode,
                ticketNum: state.ticketNum,
                paymentDetails: state.paymentDetails,
                paymentSession: state.paymentSession,
                machineInfo: state.machineInfo,
                machineId: state.machineId,
            };
        },
    },

    // other options...
});
