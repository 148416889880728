<template>
    <div id="payment_btns_separator">
        <div class="payment_btns_separator_line"></div>
        <div class="payment_btns_separator_txt">
            {{ $t('or') }}
        </div>
        <div class="payment_btns_separator_line"></div>
    </div>
</template>
<style scoped>
    #payment_btns_separator {
        padding-top: 27px;
        width: 315px;
    }

    .payment_btns_separator_txt {
        align-items: center;
        text-align: center;
        letter-spacing: 0.16px;
        color: #262626;
        white-space: initial;
        width: 50px;
        display: inline-block;
        font-weight: 600;
    }

    .payment_btns_separator_line {
        white-space: initial;
        height: 10px;
        border-bottom: solid 1px #c4c4c4;
        width: calc(50% - 31px);
        display: inline-block;
        margin-bottom: 4px;
    }
</style>
