<template>
    <div class="error-wrapper">
        <Row>
            <div id="err_msg_comp" class="col" :key="componentKey">
                <Row>
                    <div class="err_msg_pic">
                        <img :src="img" />
                    </div>
                </Row>
                <div class="row">
                    <div class="err_msg_content">
                        <h3 class="black_txt bold line_break">{{ title }}</h3>
                        <p class="black_txt" v-html="htmlBody"></p>
                    </div>
                </div>
                <div class="row" v-if="isClientErr">
                    <button class="btn btn-outline-dark main_btn" @click="backBtnCick">
                        <img src="../../assets/back_arrow.svg" />
                        <span>{{ $('back') }}</span>
                    </button>
                </div>
            </div>
        </Row>
    </div>
</template>

<script>
    import Row from '../common/RowSection.vue';
    import landingPageConst from '../../constants';
    import { isProxy, toRaw } from 'vue';

    const constants = isProxy(landingPageConst) ? toRaw(landingPageConst) : landingPageConst;
    const texts = {
        heb: {
            ereceipt: {
                table: {
                    paymentTypeLabel: {
                        creditCard: 'כרטיס אשראי',
                        gPay: 'Gpay',
                        applePay: 'Apple Pay',
                    },
                },
            },
            errors: {
                missingInformation: {
                    title: "Can't complete action",
                    body: 'Something went wrong while trying to perform the action',
                },
                paymentMethodNotAllowed: {
                    title: 'Your payment failed',
                    body: 'Your payment method was declined. Please try again with another payment method. ',
                },
                invalidCreditCard: {
                    title: 'Missing information',
                    body: "Something seems to be wrong with your card details. Please make sure you've inserted all the information correctly.",
                },
                internalServerError: {
                    title: "It's not you, it's us",
                    body: 'We have some communication problems. Please try again in a few minutes',
                },
                paymentFailed: {
                    title: 'Your payment failed',
                    body: 'Transaction failed... Your card was not charged. Please try again or contact support.',
                },
                gPayGenError: {
                    title: 'gPay payment failed',
                    body: 'gPay Transaction failed... Please try again or contact support.',
                },
                ereceiptSendFileFail: {
                    title: 'Error occurred when downloading the Ereceipt file',
                    body: 'Downloading Ereceipt file has failed... Please try again or contact support.',
                },
                ereceiptSendEmailFail: {
                    title: 'Error occurred when sending the Ereceipt file',
                    body: 'Sending email with Ereceipt file has failed... Please try again or contact support.',
                },

                requestTimeOut: {
                    title: 'Request Timeout',
                    body: 'The request has ran out of time. Your payment failed.',
                },
                noCurrencySupport: {
                    title: 'Your payment failed',
                    body: 'Sorry... Your card does not support this type of currency. ',
                },
                transLimitExceeded: {
                    title: 'Transactions Limit Exceeded',
                    body: 'Limit exceeded - Too many transactions processed on this machine today. Please try again tomorrow. ',
                },
                badGateway: {
                    title: "Oh no! We lost you. Let's reconnect:",
                    body: [
                        'Choose your product again from the machine',
                        'Scan the new QR code',
                        'Try to complete the purchase (under 2 min).',
                    ],
                },
                serviceUnavailable: {
                    title: "Oh no! We lost you. Let's reconnect:",
                    body: [
                        'Choose your product again from the machine',
                        'Scan the new QR code',
                        'Try to complete the purchase (under 2 min).',
                    ],
                },
                gatewayTimeout: {
                    title: 'Oops!',
                    body: "Looks like we broke something, but no worries, we're working on it.",
                },
            },
        },
        eng: {
            ereceipt: {
                table: {
                    paymentTypeLabel: {
                        creditCard: 'Credit Card',
                        gPay: 'Gpay',
                        applePay: 'Apple Pay',
                    },
                },
            },
            errors: {
                missingInformation: {
                    title: "Can't complete action",
                    body: 'Something went wrong while trying to perform the action',
                },
                paymentMethodNotAllowed: {
                    title: 'Your payment failed',
                    body: 'Your payment method was declined. Please try again with another payment method. ',
                },
                invalidCreditCard: {
                    title: 'Missing information',
                    body: "Something seems to be wrong with your card details. Please make sure you've inserted all the information correctly.",
                },
                internalServerError: {
                    title: "It's not you, it's us",
                    body: 'We have some communication problems. Please try again in a few minutes',
                },
                paymentFailed: {
                    title: 'Your payment failed',
                    body: 'Transaction failed... Your card was not charged. Please try again or contact support.',
                },
                gPayGenError: {
                    title: 'gPay payment failed',
                    body: 'gPay Transaction failed... Please try again or contact support.',
                },
                ereceiptSendFileFail: {
                    title: 'Error occurred when downloading the Ereceipt file',
                    body: 'Downloading Ereceipt file has failed... Please try again or contact support.',
                },
                ereceiptSendEmailFail: {
                    title: 'Error occurred when sending the Ereceipt file',
                    body: 'Sending email with Ereceipt file has failed... Please try again or contact support.',
                },

                requestTimeOut: {
                    title: 'Request Timeout',
                    body: 'The request has ran out of time. Your payment failed.',
                },
                noCurrencySupport: {
                    title: 'Your payment failed',
                    body: 'Sorry... Your card does not support this type of currency. ',
                },
                transLimitExceeded: {
                    title: 'Transactions Limit Exceeded',
                    body: 'Limit exceeded - Too many transactions processed on this machine today. Please try again tomorrow. ',
                },
                badGateway: {
                    title: "Oh no! We lost you. Let's reconnect:",
                    body: [
                        'Choose your product again from the machine',
                        'Scan the new QR code',
                        'Try to complete the purchase (under 2 min).',
                    ],
                },
                serviceUnavailable: {
                    title: "Oh no! We lost you. Let's reconnect:",
                    body: [
                        'Choose your product again from the machine',
                        'Scan the new QR code',
                        'Try to complete the purchase (under 2 min).',
                    ],
                },
                gatewayTimeout: {
                    title: 'Oops!',
                    body: "Looks like we broke something, but no worries, we're working on it.",
                },
            },
        },
    };

    export default {
        // data: () => {empty:""},
        template: '',
        props: {
            isError: Boolean,
            errCode: {},
            isClientErr: Boolean,
        },
        components: {
            Row,
        },
        watch: {},
        data() {
            return {
                componentKey: 0,
                img: '',
                title: '',
                body: '',
                errorTypes: constants.errors,
                texts: texts,
            };
        },
        mounted() {
            this.applyErrorCode();
        },
        methods: {
            backBtnCick() {
                this.$emit('err-back-click');
            },
            applyErrorCode() {
                var self = this;
                if (self.errCode < 0) return;
                var constsErr = this.errorTypes;
                var errPropName =
                    Object.keys(constsErr).find((err) => constsErr[err] === self.errCode) ??
                    'genServerErr';
                var errTexts = self.texts[errPropName];
                self.img = self.getImage(errPropName);
                self.title = errTexts?.title;
                self.body = errTexts?.body;
            },
            getImage(imageName) {
                var images = require.context('../../assets/ErrorMessage', false, /\.gif$/);
                var imagesPath = './';
                switch (imageName) {
                    case 'paymentMethodDeclined':
                    case 'paymentFailedGen':

                    case 'invalidCreditCard':
                    case 'paymentMethodNotAllowed':
                    case 'paymentFailed':
                    case 'gPayGenError':
                        imageName = 'paymentFailed';
                        break;
                    case 'badGateway':
                    case 'serviceUnavailable':
                        imageName = 'badGateway';
                        break;
                    case 'ereceiptSendFileFail':
                    case 'ereceiptSendEmailFail':
                    case 'internalServerError':
                    case 'missingInformation':
                        imageName = 'genServerErr';
                        break;
                }
                var finalPath = imageName
                    ? `${imagesPath}${imageName}.gif`
                    : `${imagesPath}genServerErr.gif`;
                return images(finalPath);
            },
        },
        computed: {
            arrowImg() {
                return '/scripts/components/errorMsgComp/images/back_arrow.svg';
            },
            htmlBody() {
                var self = this;
                var val = self.body;
                if (Array.isArray(self.body)) {
                    val = `<ol>${self.body.map((li) => '<li>' + li + '</li>').join('')}</ol>`;
                }
                return val;
            },
        },
    };
</script>
<style scoped>
    .error-wrapper {
        text-align: left;
        box-sizing: border-box;
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        background-color: #fff;
        height: 40rem;
    }
    div#err_msg_comp {
        height: 40rem;
    }
    .err_msg_pic {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 40px 0 20px;
    }

    .err_msg_content h3.black_txt.line_break {
        text-align: center;
        font-size: 18px;
        font-weight: 500;
    }
    .err_msg_content p {
        margin-top: 15px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        padding: 0 10px;
        text-transform: none;
    }
    .err_msg_content li {
        margin: 5px 0;
    }
    .main_btn {
        border-radius: 30px;
        width: 100%;
        height: 50px;
        margin-top: 50px;
        position: relative;
    }

    .main_btn img {
        position: absolute;
        left: 24px;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    .main_btn span {
        text-transform: uppercase;
        font-weight: 600;
    }
</style>
