<template>
    <footer ref="footer">
        <div class="row">
            <div class="nayax_link_list_con">
                <ul>
                    <li>
                        <a href="https://www.nayax.com/about-nayax/">
                            {{ $t('Contact Us') }}
                        </a>
                    </li>
                    <li>
                        <a href="https://www.nayax.com/terms-conditions/">
                            {{ $t('terms & conditions') }}
                        </a>
                    </li>
                    <li>
                        <a href="https://www.nayax.com/privacy_policy/">
                            {{ $t('privacy policy') }}
                        </a>
                    </li>
                    <li>
                        <a href="https://www.nayax.com/support/">{{ $t('support') }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="nayax_social_wrap">
            <div class="nayax_logo_con">
                <img src="../../assets/images/nayax_logo.svg" />
            </div>
            <div class="social_con">
                <ul>
                    <li>
                        <a href="https://www.instagram.com/nayaxvend/"
                            ><img src="../../assets/images/social/instagram.svg"
                        /></a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/nayaxvend/mycompany/"
                            ><img src="../../assets/images/social/linkdin.svg"
                        /></a>
                    </li>
                    <li>
                        <a href="https://twitter.com/NayaxGlobal"
                            ><img src="../../assets/images/social/twitter.svg"
                        /></a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/nayaxvending?fref=ts"
                            ><img src="../../assets/images/social/facebook.svg"
                        /></a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="c_tag_wrap">
            <h5 v-html="$t('&copy; all rights reserved')"></h5>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'AppFooter',
        data() {
            return {
                serverError: null,
            };
        },
        mounted: function () {
            // this.RegisterVisit();
        },
        watch: {
            'apiErrors.isError'() {
                /*
      if (!val)
          return;

      var self = this;
      setTimeout(() => { self.apiErrors.isError = false }, 2000);
      */
            },
        },
    };
</script>
