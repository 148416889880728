<template>
    <div class="inner-wrapper">
        <div class="exit-wrapper">
            <span class="exitTitle"> {{ $t('Please proceed to exit') }}</span>
            <div class="subTitle">{{ $t('No payment required') }}</div>
            <div>
                <img src="../../assets/images/ereceipt/exit.png" />
            </div>
            <div class="rectangle">{{ $t('Exit By') }} {{ exitNoLonger }}</div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import useScan2payStore from '../../store/scan2pay';
    import { formatTime } from '../../utils/formatters';

  import {
    StringToDateWithoutGMTAndMilisec,

  } from '@/utils/formatters';

    export default {
    name: 'NoPaymentRequiredView',
        methods: {
    },
     computed: {
           exitNoLonger() {
             var timeToExit = window.sessionStorage.getItem('localTimeToExitStr');
             return timeToExit;
               
            },
            ...mapState(useScan2payStore, ['scan2payInfo']),
    },
    };
</script>

<style scoped>
    .exit-wrapper {
        text-align: center;
        padding-top: 20px;
        margin-bottom: 170px;
    }
    .exitTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        /* identical to box height, or 133% */

        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        letter-spacing: 0.16px;
        font-feature-settings: 'pnum' on, 'lnum' on;

        color: #262626;
    }

    .subTitle {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 25px;
        /* identical to box height, or 166% */
        text-align: center;
        letter-spacing: -0.5px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #000000;
        padding-top: 20px;
    }

    .rectangle {
        border-radius: 25px;
        background-color: #4d4d4d;
        width: 202px;
        height: 35px;
        padding: 10px;
        margin: 0 auto;
        color: white;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: center;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        /* or 167% */

        text-align: center;
        letter-spacing: 0.16px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #ffffff;
    }
</style>
