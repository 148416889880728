import { GPayConfiguration } from '@/typings/paymentMethods';

/**
 * GPay Tutorial
 *
 * @see {@link https://developers.google.com/pay/api/web/guides/tutorial}
 * @RequestObjects {@link https://developers.google.com/pay/api/web/reference/request-objects}
 */
export default class GoogleClient {
    private _settings: GPayConfiguration;

    private _paymentsClient: google.payments.api.PaymentsClient;

    private baseRequest = {
        apiVersion: 2,
        apiVersionMinor: 0,
    };

    constructor(settings: GPayConfiguration, onReady: () => void) {
        this._settings = { ...settings };
        this._paymentsClient = new google.payments.api.PaymentsClient({
            environment: this._settings.environment,
            merchantInfo: this.getMerchantInfo(),
        });

        this.load(onReady);
    }

    private getBaseCardPaymentMethod(): google.payments.api.IsReadyToPayPaymentMethodSpecification {
        const parameters: google.payments.api.CardParameters = {
            allowedAuthMethods: [...this._settings.allowedPaymentMethods],
            allowedCardNetworks: [...this._settings.allowedCardNetworks],
        };
        const baseCardPaymentMethod: google.payments.api.IsReadyToPayPaymentMethodSpecification = {
            type: 'CARD',
            parameters: { ...parameters },
        };
        return Object.assign({}, baseCardPaymentMethod);
    }
    private getGoogleIsReadyToPayRequest(): google.payments.api.IsReadyToPayRequest {
        return Object.assign({}, this.baseRequest, {
            allowedPaymentMethods: [this.getBaseCardPaymentMethod()],
        });
    }

    private getGooglePaymentDataRequest(
        transactionInfo: google.payments.api.TransactionInfo
    ): google.payments.api.PaymentDataRequest {
        const tokenizationSpecification = this.getTokenizationSpecification();

        const cardPaymentMethod: google.payments.api.PaymentMethodSpecification = Object.assign(
            {},
            this.getBaseCardPaymentMethod(),
            {
                tokenizationSpecification,
            }
        );

        return Object.assign({}, this.baseRequest, {
            allowedPaymentMethods: [cardPaymentMethod],
            merchantInfo: this.getMerchantInfo(),
            transactionInfo: transactionInfo,
        });
    }
    private getTokenizationSpecification(): google.payments.api.PaymentMethodTokenizationSpecification {
        if (this._settings.publicKey) {
            return {
                type: 'DIRECT',
                parameters: {
                    protocolVersion: 'ECv2',
                    publicKey: this._settings.publicKey,
                },
            };
        }

        return {
            type: 'PAYMENT_GATEWAY',
            parameters: {
                gateway: this._settings.gateway!,
                gatewayMerchantId: this._settings.gatewayMerchantId,
            },
        };
    }
    private getMerchantInfo(): google.payments.api.MerchantInfo {
        return {
            merchantName: this._settings.merchantName,
            merchantId: this._settings.merchantId,
        };
    }

    // @todo prefetch payment data to improve performance after confirming site functionality
    private PrefetchPaymentData(transactionInfo: google.payments.api.TransactionInfo) {
        const paymentDataRequest = this.getGooglePaymentDataRequest(transactionInfo);
        this._paymentsClient.prefetchPaymentData(paymentDataRequest);
    }

    private load(onSuccess: () => void) {
        const request = this.getGoogleIsReadyToPayRequest();
        this._paymentsClient
            .isReadyToPay(request)
            .then(function (response) {
                if (response.result) {
                    onSuccess();
                }
            })
            .catch(function (err) {
                // show error in developer console for debugging
                console.error(err);
            });
    }
    public GooglePay(
        transactionInfo: google.payments.api.TransactionInfo,
        onSuccess: (a: string) => void,
        onFailure?: (a: any) => void
    ) {
        const paymentDataRequest = this.getGooglePaymentDataRequest(transactionInfo);
        console.log(paymentDataRequest);
        this._paymentsClient
            .loadPaymentData(paymentDataRequest)
            .then(function (paymentData) {
                onSuccess(paymentData.paymentMethodData.tokenizationData.token);
            })
            .catch(function (err) {
                onFailure?.(err);
            });
    }
}

// export let Instance: GoogleClient | null = null;
// export function SetInstance(googleClient: GoogleClient) {
//     Instance = googleClient;
// }
