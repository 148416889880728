<template>
    <div class="row">
        <div class="row-header" v-if="text">
            {{ text }}
        </div>
        <slot />
    </div>
</template>
<script>
    export default {
        props: {
            text: { required: false, type: String },
        },
    };
</script>
<style scoped>
    .row {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        text-align: left;

        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        margin-right: 0px;
        margin-left: 0px;
    }
    .row-header {
        box-sizing: border-box;

        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.16px;
        color: #262626;
        width: 100%;
        padding-bottom: 0;
    }
</style>
