export default {
    paymentType: {
        creditCard: 1,
        generic_payment: 158,
        gPay: 166,
        applePay: 165,
        gPay_chase: 229,
        applePay_chase: 228,
    },
    errors: {
        invalidCreditCard: 150,
        paymentMethodNotAllowed: 151,
        paymentFailed: 152,
        missingInformation: 153,
        requestTimeOut: 154,
        noCurrencySupport: 155,
        transLimitExceeded: 156,
        gPayGenError: 157,
        ereceiptSendFileFail: 158,
        ereceiptSendEmailFail: 159,

        internalServerError: 500,
        badGateway: 502,
        serviceUnavailable: 503,
        gatewayTimeout: 504,
        unknownIssue: 162,
    },
};
export const GOOGLE_PAYMENT_METHOD = 'googlepayecom';
export const APPLE_PAYMENT_METHOD = 'applepayecom';
export const CREDIT_CARD_PAYMENT_METHOD = 'credit_card';
export const GOOGLE_PAYMENT_METHOD_CHASE = 'googlepay_via_chase';
export const APPLE_PAYMENT_METHOD_CHASE = 'applepay_via_chase';
