/*export function parseISOString(s) {
    if (s === undefined) return null;
    var b = s.split(/\D+/);
    console.log('Iso:' + s);
    console.log('UTC:' + new Date(b[0], --b[1], b[2], b[3], b[4], b[5]));
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5]));
}*/

export function formatDate(date, documentDirection) {
    if (date === undefined) return null;

    if (documentDirection === undefined || documentDirection === 'ltr') {
        return new Intl.DateTimeFormat('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        }).format(date);
    } else
        return new Intl.DateTimeFormat('he-IL', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        }).format(date);
}

/*export function formatIsoDate(date) {
    if (date === undefined) return null;
    return new Intl.DateTimeFormat('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    }).format(parseISOString(date));
}*/

export function formatTime(date) {
    if (date === undefined) return null;
    return new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    }).format(date);
}

/*export function formatIsoTime(date) {
    if (date === undefined) return null;
    return new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    }).format(parseISOString(date));
}*/

export function formatHours(totalMinutes, $t) {
    var days = Math.floor(totalMinutes / (3600 * 24));
    var hours = Math.floor((totalMinutes % (3600 * 24)) / 3600);
    var minutes = Math.floor((totalMinutes % 3600) / 60);

    if ($t) {
        if (days === 0 && hours === 0) return `${minutes}` + $t('m');
        if (days === 0) return `${hours}` + $t('h') + ` ${minutes}` + $t('m');
        else return `${days}` + $t('d') + ` ${hours}` + $t('h') + ` ${minutes}` + $t('m');
    } else {
        if (days === 0 && hours === 0) return `${minutes}m`;
        if (days === 0) return `${hours}h ${minutes}m`;
        else return `${days}d ${hours}h ${minutes}m`;
    }
}

export function formatCurrency(number, currencyCode) {
    if (isNaN(number)) {
        return '';
    }
    number = number / 100;
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyCode || 'USD',
    });
    return formatter.format(number);
}

export function Devide1000(number, currencyCode) {
    if (isNaN(number)) {
        return '';
    }
    return number / 1000;
}

