<template>
    <div class="security-icons">
        <img src="@/assets/images/security/secure.svg" />
        <img src="@/assets/images/security/pci.svg" />
        <img src="@/assets/images/security/comodo.svg" />
    </div>
</template>

<style>
    .security-icons {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        display: flex;
        margin-top: 30px;
        align-items: center;
        justify-content: space-evenly;
        justify-content: space-evenly;
        margin-bottom: 10px;
    }
    .security-icons img {
        padding: 6px;
        width: 75px;
        image-rendering: pixelated;
    }
</style>
