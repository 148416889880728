import { defineStore } from 'pinia';

const state = () => ({
    session: null,
});

const getters = {
    paymentSessionInfo(state) {
        return {
            session: state.session,
        };
    },
};

const actions = {
    setPaymentSession(value) {
        this.session = value;
    },
};

export default defineStore('paymentSessionStore', {
    state,
    actions,
    getters,
    // other options...
});
