<template>
    <div class="main">
        <div class="wrapper exitText">
            <label>{{ $t('Please proceed to exit') }}</label>
            <img src="//static.nayax.com/machine_Landing_page_asstes/success4.gif" />
            <label>{{ $t('Exit no later than:') }} {{ timeToExit }} </label>
            <label class="exitTextSmall">{{ $t('additional fees may apply') }}</label>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            img: String,
            timeToExit: String,
        },
    };
</script>
<style scoped>
    .main {
        text-align: left;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #f7f7f7;
        z-index: 99999;
        opacity: 0.85;
    }
    .wrapper {
        box-sizing: border-box;
        position: absolute;
        right: 0;
        left: 0;
        margin: 0 auto;
        top: 20%;
        justify-content: center;
        display: flex;
        animation-name: fade_in;
        animation-duration: 1s;
        display: block;
    }
    .wrapper label {
        display: block;
    }
    .exitText {
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0px;
        text-align: center;
        font-size: 10px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0px;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: center;
    }
    .exitTextSmall {
        font-size: 12px;
    }
</style>
