<template>
  <SendingOverlay v-show="isEmailSending" />
  <SuccessExitOverlay class="success_pay_con"
                      img="//static.nayax.com/machine_Landing_page_asstes/success4.gif"
                      :timeToExit="timeToExitOverlay"
                      v-if="showTimeToExitOverlay" />
  <div class="inner-wrapper">
    <div class="exit-wrapper">
      <span class="exitTitle"> {{ $t('Please proceed to exit') }}</span>
      <div v-show="!isEmailSending">
        <img src="../../assets/images/ereceipt/exit.png" />
      </div>
      <div class="rectangle" v-show="!isEmailSending">
        {{ $t('Exit not later than') }}
        <span style="color: #ffcd00">{{ exitNoLonger }}</span>
      </div>
    </div>
    <hr />
    <div class="inner-wrapper" :key="componentKey">
      <!--ereceipt section-->
      <div id="ereceipt_wrap">
        <Row>
          <div class="section_header col center">
            <h3 class="black_txt bold line_break">
              {{ $t('Payment Receipt') }}
            </h3>
          </div>
        </Row>
        <div class="row" @click.capture="cancelEmailFlow">
          <div class="ereceipt_table_wrap col">
            <table id="ereceipt_table">
              <tr>
                <td style="display: flex">
                  <img src="../../assets/images/machine/vector.png"
                       style="height: 15.4px" />
                  <div class="location_icon">{{ $t('Location') }}</div>
                </td>
                <td>{{ ereceipt.Location }}</td>
              </tr>

              <tr class="payment_type_image_row">
                <td>
                  <div class="payment_type_image" style="justify-content: normal">
                    <img v-if="paymentTypePic" :src="paymentTypePic" />
                  </div>
                </td>
                <td>
                  <div style="display: inline-flex">
                    {{ paymentTypeLabel }}
                    <div v-if="ereceipt.PaymentMethod == 1">
                      |
                      <span v-for="index in 4"
                            :key="index"
                            class="cardBullet">
                        <svg height="4" width="4">
                          <circle cx="2" cy="2" r="2" fill="black" />
                        </svg>
                      </span>
                      {{ ereceipt.CardLast4Digits }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t('Transaction Id') }}
                </td>
                <td class="semiBold">#{{ ereceipt.EngTransactionId }}</td>
              </tr>

              <tr>
                <td>{{ $t('Ticket #') }}</td>
                <td>{{ ereceipt.TicketNumber }}</td>
              </tr>

              <tr>
                <td class="semiBold" style="color: #262626">{{ $t('Entry') }}</td>
                <td style="font-weight: 600">
                  <label> {{ formatToDate(ereceipt.EntryDateTime) }} </label>&nbsp;
                  {{ formatToTime(ereceipt.EntryDateTime) }}
                </td>
              </tr>
              <tr>
                <td class="semiBold" style="color: #262626">
                  {{ $t('Duration') }}
                </td>
                <td>
                  {{ formatToHours(ereceipt.Duration) }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('Net') }}</td>
                <td>
                  {{ formatToCurrency(ereceipt.NetFee) }}
                </td>
              </tr>
              <tr v-show="ereceipt.Tax1Value > 0">
                <td>{{ $t('Tax') }} {{ DevideBy1000(ereceipt.Tax1Rate) }}%</td>
                <td>
                  {{ formatToCurrency(ereceipt.Tax1Value) }}
                </td>
              </tr>
              <tr v-show="ereceipt.Tax2Value > 0">
                <td>{{ $t('Tax') }} {{ DevideBy1000(ereceipt.Tax2Rate) }}%</td>
                <td>
                  {{ formatToCurrency(ereceipt.Tax2Value) }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('Discount') }}</td>
                <td style="color: #007aff">
                  <label v-show="ereceipt.HasDiscount">-</label>
                  {{ formatToCurrency(ereceipt.ValidationsSum) }}
                </td>
              </tr>

              <tr class="total_price_row">
                <td>{{ $t('Total Price') }}</td>
                <td>
                  <h3 class="black_txt bold">
                    {{ formatToCurrency(ereceipt.ChargeAmount) }}
                  </h3>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <!-- ereceipt handlers wrap -->
        <div class="ereceipt_handlers_wrap col">
          <Row>
            <div class="button_group">
              <!-- <button
                  :class="{ hideAnimation: isEmailFlow }"
                  class="card-form__button"
                  @click="saveEreceipt"
              >
                  <img src="../../assets/images/ereceipt/save.svg" />
                  <span>{{ $t('Save') }}</span>
              </button>
              -->

              <a target="_blank"
                 class="sendButton"
                 style="text-align: center"
                 :style="{
                                    direction: isRtl ? 'rtl' : 'ltr',
                                }"
                 :href="ereceipt.ReceiptUrl">
                <div class="save_img">
                  <img src="../../assets/images/ereceipt/save.svg"
                       style="margin-right: 6px" />
                  <span style="top: 3px; position: relative">
                    {{
                                        $t('Save')
                    }}
                  </span>
                </div>
              </a>
              <div class="send_button_container"
                   :style="{
                                    width: isEmailFlow ? '100%' : '',
                                    direction: isRtl ? 'rtl' : 'ltr',
                                }">
                <button id="sendReceiptBtn"
                        class="card-form__button"
                        :class="isEmailFlow ? 'sendReceiptEmail' : 'sendReceipt'"
                        @click="sendEreceiptHandle"
                        :disabled="isEmailSending">
                  <img src="../../assets/images/ereceipt/send.svg"
                       style="margin-right: 6px"
                       :style="{ display: isEmailFlow ? 'none' : 'inline-block' }" />
                  <span>{{ $t('Send') }}</span>
                </button>

                <input v-model="email"
                       class="ereceipt_email_input"
                       :class="{
                                        email_flow_input: isEmailFlow,
                                    }"
                       ref="ereceipt_email_input"
                       @keyup="handleEmailKeyPress"
                       :style="{
                                        borderColor: isValidErr ? '#AA0000' : '#262626',
                                    }" />
                <div class="err_validation_con" v-show="isValidErr && isEmailFlow">
                  <span class="err">{{ $t('Invalid Email Address') }}</span>
                </div>
              </div>
              <div class="row" style="height: 15px; margin-top: 15px; " v-show="isEmailSuccess">
                <div class="cc_send_receipt_con" :style="{direction: isRtl ? 'rtl' : 'ltr'}">
                  <transition name="slide-fade-up">
                    <span id="validationMsg">{{ $t('The email has been sent') }}</span>
                  </transition>
                </div>
              </div>
            </div>
          </Row>
          <!--
              <div class="row">
                  <button class=" card-form__button test" @click="window.open('http://nayax-abt-ereceipt-qa.s3.amazonaws.com/2020/05/24/mot/n6IjoDxp0EuhI0hQv06APw2/ereceipt3211.pdf')">test link download</button>
              </div>
          -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import landingPageConst from '@/constants';
  import Row from '@/components/common/RowSection.vue';
  import SendingOverlay from '@/components/common/SendingOverlay.vue';
  import {
    formatDate,
    formatTime,
    formatHours,
    formatCurrency,
    Devide1000,
    StringToDateWithoutGMTAndMilisec,
  } from '@/utils/formatters';
  import SuccessExitOverlay from '@/components/Scan2Pay/SuccessExitOverlay.vue';
  import apiClient from '@/utils/apiClient';
  import { useRouter } from 'vue-router';
  import useScan2payStore from '../../store/scan2pay';
  const router = useRouter();

  export default {
    components: { Row, SendingOverlay, SuccessExitOverlay },
    data: () => ({
      componentKey: 0,
      transactionCode: '',
      landingPageConst: landingPageConst,
      //step: landingPageConst.steps.init,
      appStoreUrl: 'https://apps.apple.com/us/app/monyx-wallet/id1455956409',
      playStoreUrl: 'https://play.google.com/store/apps/details?id=com.monyx.wallet',
      storeurl: '',
      ereceipt: {
        MachineName: '',
        MachineId: '',
        MachineType: '',
        EngTransactionId: '123456',
        EngMachineAuTime: '',
        ChargeAmount: '',
        PaymentMethod: '',
        CardBrand: '',
        CardLast4Digits: '4580',
        CurrencySign: '',
        MachineTypeLutImage: '',
        VAT: '',
        TicketNumber: '',
        EntryDateTime: undefined,
        Duration: '',
        TimeToExit: '',
        Location: '',
        TotalFee: '',
        ValidationsSum: '',
        HasDiscount: false,
        ServiceFee: '',
        PaymentDateTime: undefined,
        CurrencyCode: undefined,
        ReceiptUrl: undefined,
        NetFee: '',
        Tax1Rate: '',
        Tax2Rate: '',
        Tax1Value: '',
        Tax2Value: '',
      },
      ereceiptFileModel: {
        fileName: '',
        fileUrl: '',
      },
      isEmailFlow: false,
      isEmailSending: false,
      email: '',
      isValidErr: false,
      isAppErr: false,
      isEmailSuccess: false,
      timeToExitOverlay: 0,
      showTimeToExitOverlay: true,
      isRtl: false,
    }),

    mounted: function () {
      var self = this;
      self.isRtl = document.dir == 'rtl';

      self.transactionCode = this.$route.params.transaction; // 'FmTryGjbt5nACEatneOskQ2';
      var timeToExit = window.sessionStorage.getItem('localTimeToExitStr');

      self.timeToExitOverlay = timeToExit;

      self.showTimeToExitOverlay = true;
      apiClient.Scan2Pay.chargeDetails(self.transactionCode)
        .then((res) => {
          var model = res.data;
          if (res.data === null || res.data === '') {
            self.$router.push({
              name: 'Search'
            });
          }
          self.ereceipt.MachineName = model.machineName;
          self.ereceipt.MachineId = model.machineId;
          self.ereceipt.MachineType = model.machineType;
          self.ereceipt.EngTransactionId = model.engTransactionId;
          self.ereceipt.EngMachineAuTime = model.engMachineAuTime;
          self.ereceipt.ChargeAmount = model.chargeAmount * 100;
          self.ereceipt.PaymentMethod = model.paymentMethod;
          self.ereceipt.CardBrand = model.cardBrand?.replace(' ', '_').toLowerCase();
          self.ereceipt.CardLast4Digits = model.cardLast4Digits;
          self.ereceipt.CurrencySign = model.currencySign;
          self.ereceipt.VAT = model.VAT;
          self.ereceipt.MachineTypeLutImage = model.machineTypeLutImage;
          self.ereceipt.TicketNumber = model.ticketNumber;
          self.ereceipt.EntryDateTime = new Date(model.entryDateTime);
          self.ereceipt.Duration = model.duration;
          self.ereceipt.Location = model.location;
          self.ereceipt.TotalFee = model.totalFee;
          self.ereceipt.ValidationsSum = model.validationsSum;
          self.ereceipt.HasDiscount = model.validationsSum > 0;
          self.ereceipt.ServiceFee = model.serviceFee;
          self.ereceipt.CurrencyCode = model.currencyCode;
          if (model.receipt) self.ereceipt.ReceiptUrl = model.receipt.receiptURL;
          self.componentKey += 1;
          self.ereceipt.NetFee = model.netFee;
          self.ereceipt.Tax1Rate = model.tax1Rate;
          self.ereceipt.Tax2Rate = model.tax2Rate;
          self.ereceipt.Tax1Value = model.tax1Value;
          self.ereceipt.Tax2Value = model.tax2Value;

          setTimeout(() => {
            self.showTimeToExitOverlay = false;
          }, 3000);
        })
        .catch((err) => {
          self.setClientError(err);

        });
    },
    computed: {
      paymentTypePic() {
        console.log(landingPageConst);
        console.log(this.ereceipt.PaymentMethod);
        if (!this.ereceipt.PaymentMethod) return;

        const paymentType = this.getPaymentType(this.ereceipt.PaymentMethod);

        const cardBrand = this.ereceipt.CardBrand
          ? require('../../assets/images/scan2pay/payment/' +
            this.ereceipt.CardBrand +
            '.svg')
          : '';

        const paymentIcon =
          this.ereceipt.PaymentMethod != this.landingPageConst.paymentType.creditCard
            ? require('../../assets/images/scan2pay/payment/' + paymentType + '.svg')
            : '';

        return this.ereceipt.PaymentMethod === this.landingPageConst.paymentType.creditCard
          ? this.ereceipt.CardBrand
            ? cardBrand
            : ''
          : paymentIcon;
      },
      paymentTypeLabel() {
        return this.getPaymentMethodLabel(this.ereceipt.PaymentMethod);
      },
      cardNumberPresent() {
        return '&#183;&#183;&#183;&#183;' + this.ereceipt.CardLast4Digits;
      },
      exitNoLonger() {
        var timeToExit = window.sessionStorage.getItem('localTimeToExitStr');

        return timeToExit;
      },
    },
    methods: {
      formatToDate(date) {
        if (date === undefined) return null;
        let documentDirection = document.dir;
        return formatDate(new Date(date), documentDirection);
      },
      formatToTime(date) {
        return formatTime(date);
      },
      formatToHours(minutes) {
        return formatHours(minutes, this.$t);
      },
      formatToCurrency(number) {
        return formatCurrency(number, this.ereceipt.CurrencyCode);
      },
      DevideBy1000(number) {
        return Devide1000(number, this.ereceipt.CurrencyCode);
      },
      parseDecimal(value) {
        if (!value) return;
        return value.toFixed(2);
      },
      cancelEmailFlow() {
        this.isEmailFlow = false;
      },
      validateEmail(emailAddress) {
        emailAddress = typeof emailAddress === 'object' ? emailAddress.val() : emailAddress;
        var localPartRgx = /^(([^<>()[\]\\.,;:\s@"']+(\.[^<>()[\]\\.,;:\s@"']+)*)|(".+"))@/;
        var domainRgx =
          /((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var rgex = new RegExp(localPartRgx.source + domainRgx.source);
        return rgex.test(emailAddress);
      },
      handleValidateEmail() {
        var self = this;
        var result = self.validateEmail(self.email);
        if (!result) {
          self.isValidErr = true;
        } else {
          self.isValidErr = false;
        }
        return result;
      },
      getPaymentMethodLabel(paymentMethod) {
        var paymentType = this.getPaymentType(paymentMethod);
        return paymentType == 'applePay'
          ? 'Apple Pay'
          : paymentType == 'gPay'
            ? 'Google Pay'
            : '';
      },

      getPaymentType(paymentMethod) {
        if (
          paymentMethod == this.landingPageConst.paymentType.applePay ||
          paymentMethod == this.landingPageConst.paymentType.applePay_chase
        )
          return 'applePay';
        else if (
          paymentMethod == this.landingPageConst.paymentType.gPay ||
          paymentMethod == this.landingPageConst.paymentType.gPay_chase
        )
          return 'gPay';
        else return '';
      },
      sendEreceiptHandle() {
        var self = this;
        if (!self.isEmailFlow) {
          self.isEmailFlow = true;

          setTimeout(() => {
            self.$refs.ereceipt_email_input.focus();
          }, 500);
        } else if (self.isEmailFlow) {
          if (!self.handleValidateEmail()) return;

          self.isEmailSending = true;
          apiClient.Scan2Pay.sendEreceipt(self.transactionCode, self.email)
            .then(() => {
              setTimeout(() => {
                self.isEmailSuccess = true;
                self.isEmailSending = false;
                self.cancelEmailFlow();
                setTimeout(() => {
                  self.isEmailSuccess = false;
                }, 5000);
              }, 3000);
            })
            .catch((ex) => {
              self.isAppErr = !!ex;
              self.setClientError(ex);
              self.isEmailSuccess = false;
              self.isEmailSending = false;
            });

        }
      },

      saveEreceipt() {
        var self = this;
        apiClient.Scan2Pay.sendEreceipt(self.transactionCode)
          .then((res) => {
            //self.ereceiptFileModel.fileName = res.data.receiptFileName;
            self.ereceiptFileModel.fileUrl = res.data.receiptURL;
            window.open(self.ereceiptFileModel.fileUrl);
            //saveAs(self.ereceiptFileModel.fileUrl, res.data.receiptFileName);
            //self.downloadFile(res.data.receiptFileName, self.ereceiptFileModel.fileUrl);
          })
          .catch((ex) => {
            self.isAppErr = !!ex;
            self.setClientError(ex);
          });
      },

      downloadFile(fileName, url) {
        var link = document.createElement('a');
        link.download = fileName;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      setClientError(err) {
        console.log(err);
        router.push({
          name: 'error',
          params: {
            type: 'receipt',
          },
        });
      },
    },
  };
</script>

<style scoped>
  .exit-wrapper {
    text-align: center;
    padding-top: 20px;
  }

  .exitTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 24px;
    /* identical to box height, or 133% */

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    letter-spacing: 0.16px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #262626;
  }

  .cc_send_receipt_con {
    padding: 5px 20px;
    font-size: 10px;
    background: #D5FDEC;
    border-radius: 30px;
    width: 100%;
    text-align: center;
  }

    .cc_send_receipt_con span {
      font-size: 0.8rem;
      left: 10px;
      color: #007C48;
    }

  .rectangle {
    border-radius: 25px;
    background-color: #4d4d4d;
    width: 202px;
    height: 35px;
    padding: 10px;
    margin: 0 auto;
    color: white;
    display: inline;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    /* or 167% */

    text-align: center;
    letter-spacing: 0.16px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #ffffff;
  }

  .ereceipt_handlers_wrap .button_group img {
    width: 1.2rem;
  }

  .ereceipt_handlers_wrap .button_group button,
  .white_button {
    background-color: white;
    border: 1px solid #262626;
    color: #262626;
    text-transform: capitalize;
    font-size: 18px;
    width: 9rem;
    transition: all 0.5s ease-in;
    opacity: 1;
    font-weight: 600;
  }

    .ereceipt_handlers_wrap .button_group button:focus {
      outline: none;
    }

  .ereceipt_handlers_wrap.col {
    padding-bottom: 55px;
    direction: ltr;
  }

  .ereceipt_email_input {
    background-color: #f7f7f7;
    font-size: 0.9rem;
    border: 1px solid #262626;
    color: #262626;
    width: 5rem;
    position: absolute;
    transition: all 0.3s ease-in;
    right: 0;
    bottom: 0;
    height: 50px;
    top: 0;
    border-radius: 440px;
    z-index: -1;
    visibility: hidden;
    outline: none;
  }

    .ereceipt_email_input.email_flow_input {
      width: 100%;
      visibility: visible;
      z-index: 1;
      padding: 18px;
    }

  .send_button_container {
    position: relative;
    height: 40px;
    margin-top: 20px;
    float: right;
  }

  [dir='trl'].send_button_container {
    width: 9rem;
  }

  .err_validation_con {
    position: relative;
    top: 2rem;
    background-color: #ffeded;
    text-align: center;
    margin-top: 25px;
    border-radius: 30px;
  }

  .ereceipt_handlers_wrap .button_group .send_button_container button {
    margin-top: 0;
    right: 0;
    z-index: 2;
    position: absolute;
  }

  [dir='rtl'] .ereceipt_handlers_wrap .button_group .send_button_container button {
    left: 0;
    right: revert;
  }

  .send_button_container span.err {
    font-size: 0.8rem;
    color: #aa0000;
  }

  [dir='rtl'] .send_button_container span.err {
    right: 10px;
  }

  .sendButton {
    height: 50px;
    background: #e0e0e0;
    border-radius: 30px;
    font-weight: 600;
    margin-top: 20px;
    cursor: pointer;
    background-color: white;
    border: 1px solid #262626;
    color: #262626;
    text-transform: capitalize;
    font-size: 18px;
    width: 9rem;
    transition: all 0.5s ease-in;
    opacity: 1;
  }

  .button {
    width: 100px;
    height: 50px;
    -moz-box-shadow: inset 0 1px 0 0 #fff;
    -webkit-box-shadow: inset 0 1px 0 0 #fff;
    box-shadow: inset 0 1px 0 0 #fff;
    background: -webkit-gradient( linear, left top, left bottom, color-stop(0.05, #ffffff), color-stop(1, #d1d1d1) );
    background: -moz-linear-gradient(center top, #ffffff 5%, #d1d1d1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#d1d1d1');
    background-color: #fff;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    border: 1px solid #dcdcdc;
    display: inline-block;
    color: #777;
    font-size: 15px;
    font-weight: 700;
    padding: 6px 24px;
    text-decoration: none;
    text-shadow: 1px 1px 0 #fff;
  }

    .button:hover {
      background: -webkit-gradient( linear, left top, left bottom, color-stop(0.05, #d1d1d1), color-stop(1, #ffffff) );
      background: -moz-linear-gradient(center top, #d1d1d1 5%, #ffffff 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d1d1d1', endColorstr='#ffffff');
      background-color: #d1d1d1;
    }

    .button:active {
      position: relative;
      top: 1px;
    }

  .button_group {
    margin-right: 30px;
    margin-left: 30px;
  }

  .semiBold {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.2599999010562897px;
    text-align: left;
  }

  table#ereceipt_table td:last-child {
    width: 70%;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.1599999964237213px;
    text-align: right;
  }

  .ereceipt_handlers_wrap .button_group .hideAnimation {
    animation-name: hide-button;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  .ereceipt_handlers_wrap .button_group .sendReceiptEmail {
    width: 28%;
  }

  .ereceipt_handlers_wrap .button_group .sendReceipt {
    width: 9rem;
  }

  table#ereceipt_table {
    width: 100%;
  }

    table#ereceipt_table td {
      width: 50%;
      padding: 15px 0;
    }

      table#ereceipt_table td:first-child {
        text-align: left;
        color: #606468;
        text-transform: capitalize;
        width: 30%;
      }

  [dir='rtl'] table#ereceipt_table td:first-child {
    text-align: right;
  }

  table#ereceipt_table td:last-child {
    text-align: right;
    width: 70%;
    font-weight: 500;
    font-size: 16px;
  }

  [dir='rtl'] table#ereceipt_table td:last-child {
    text-align: left;
  }

  table#ereceipt_table tr {
    border-top: 1px solid #dedede;
  }

    table#ereceipt_table tr:last-child {
      border-bottom: 1px solid #dedede;
    }

  table#ereceipt_table .total_price_row h3 {
    font-weight: 600;
    font-size: 18px;
  }

  table#ereceipt_table tr:first-child td:first-child {
    width: 20%;
  }

  table#ereceipt_table tr:first-child td:last-child {
    width: 80%;
  }

  .ereceipt_table_wrap.col {
    font-size: 16px;
    padding: 0 30px;
  }

  table#ereceipt_table tr.machine_type_image_row td,
  table#ereceipt_table tr.payment_type_image_row td {
    padding: 5px 0;
  }

  table#ereceipt_table tr.total_price_row td {
    padding: 25px 0 5px 0;
  }

  @media (max-width: 500px) {
    .button_group {
      margin-right: 10px;
      margin-left: 10px;
    }

    [dir='rtl'].button_group {
      margin-right: -20px;
    }

    .sendButton {
      height: 40px;
      width: 8rem;
      font-size: 0.9rem;
    }

    .ereceipt_handlers_wrap .button_group .sendReceipt {
      width: 8rem;
    }

    .exitTitle {
      font-size: 18px;
    }

    table#ereceipt_table td:last-child {
      font-size: 12px;
    }

    .ereceipt_table_wrap.col {
      font-size: 0.8rem;
      padding: 0 30px;
    }

    .semiBold {
      font-size: 13px;
    }

    .ereceipt_handlers_wrap .button_group button {
      font-size: 0.9rem;
    }
  }

  .location_icon {
    margin-left: 5px;
  }

  [dir='rtl'] .location_icon {
    margin-right: 5px;
  }

  [dir='rtl'] .send_button_container img,
  .save_img img {
    margin-right: 0px;
    margin-left: 6px;
  }
</style>
