<template>
    <div v-if="machine">
        <div class="row">
            <div class="col machine_pic_warrper" id="operator_cover_pic">
                <img :src="machine.machinePic" class="machine_pic" />
            </div>
        </div>
        <div class="content" id="machine_info" style="padding-left: 10.3333%">
            <div>
                <img src="../../assets/images/machine/vector.png" />
            </div>
            <h4 class="machine_name bold">{{ machine.machineLocation }}</h4>
        </div>
    </div>
</template>

<script setup>
    import useStore from '../../store/scan2pay';
    import { computed } from 'vue';
    const scan2PayStore = useStore();
    const machine = computed(() => scan2PayStore.machineInfo);
</script>

<style scoped>
    #operator_cover_pic {
        height: 200px;
        width: 100%;
        background-color: white;
        overflow: hidden;
        position: relative;
        margin: auto;
        border-bottom: 1px solid #e4e4e4;
    }

    #operator_cover_pic img {
        vertical-align: middle;
        border-style: none;
        position: absolute;
        left: 0%;
        max-width: 100%;
        object-fit: cover;
        max-height: 100%;
        object-position: center;
    }

    .content {
        display: flex;
    }

    .content img {
        margin-right: 11px;
        display: block;
    }

    [dir='rtl'] .content img {
        margin-left: 11px;
        margin-top: 4px;
        margin-right: -3px;
    }

    .machine_pic {
        text-align: center;
        width: 100%;
        height: 100%;
        margin: 0 auto;
    }
    .machine_info_warpper {
        display: flex;
        justify-content: center;
    }
    #machine_info {
        /*padding: 8px;
        padding-right: 38px;
        padding-bottom: 15px;
        background-color: transparent;
        margin-top: 25px;
        margin-bottom: 50px;*/
        border-bottom: 1px solid #e4e4e4;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10.3333%;
    }
    [dir='rtl'] #machine_info {
        /*border-bottom: 1px solid #e4e4e4;
        padding-top: 10px;
        padding-bottom: 10px;*/
        padding-right: 10.3333%;
    }
    hr {
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid #e4e4e4;
        margin: 1em 0;
        padding: 0;
    }

    .machine_location {
        text-align: left;
    }

    [dir='rtl'] .machine_location {
        text-align: right;
    }
    [dir='rtl'] .machine_name {
        float: right;
        margin-top: 5px;
    }
</style>
