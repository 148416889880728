import { translate } from '@/utils/translation';
import { Store } from 'pinia';
import { App, Plugin } from 'vue';

type TranslationStore = Store<any, { currentLanguage: null | string }>;

const validateOptions = (options: TranslationOptions) => {
    if (!options?.store)
        throw new Error(
            'Translation Plugin: No store was defined. Please set store on options.store'
        );
};
interface TranslationOptions {
    store: TranslationStore;
}

const plugin: Plugin = {
    install: (app: App, options: TranslationOptions) => {
        validateOptions(options);
        const store = options.store;
        const translateWithStore = (text: string) => translate(text, store.currentLanguage);
        app.config.globalProperties.$t = translateWithStore;
        app.provide('$t', translateWithStore);
    },
};

export default plugin;
