export default {
    data() {
        return {
            apiErrors: {
                isError: false,
                errCode: -1,
                isClientErr: false,
            },
        };
    },
    methods: {
        setClientError(err) {
            var self = this;
            if (!err.response) return;

            if (err.response && err.response.data.details) {
                if (err.response.data.details < 500) {
                    self.apiErrors = {
                        isClientErr: true,
                        isError: true,
                        errCode: err.response.data.details,
                    };
                } else {
                    self.$router.push(`/error/${err.response.data.details}`);
                }
            }
        },
    },
};
