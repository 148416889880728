import TransactionInfo from '@/models/landingPage';
import { TransactionInfoApiModel } from '@/typings/apiClient/common';

const GENERAL_MACHINE_COVER_IMAGE = 'machine_type_general';
const S3_PATH =
    'https://s3.amazonaws.com/static.nayax.com/machine_Landing_page_asstes/default_images/';

export const mapToTransactionInfo = (
    paymentData: TransactionInfoApiModel,
    transactionInfo: TransactionInfo
) => {
    transactionInfo.publicKeyFromPem = paymentData.publicKey;
    transactionInfo.totalPrice = paymentData.totalPrice;
    transactionInfo.machine.machineId = paymentData.machineId;
    transactionInfo.machine.machinePicture = paymentData.machinePic;
    transactionInfo.machine.machineName = paymentData.machineName;
    transactionInfo.machine.machineNumber = paymentData.machineNumber;
    transactionInfo.machine.rating = paymentData.rating;
    transactionInfo.machine.products = paymentData.products;
    transactionInfo.machine.currencySign = paymentData.currencySign;
    transactionInfo.machine.distImage = paymentData.distImage;
    transactionInfo.machine.machineTypeLutCkey = paymentData.machineTypeLutCkey;
    transactionInfo.machine.machineTypeLutImage = paymentData.machineTypeLutImage;
    transactionInfo.machine.machinePic = HandleMachinePic(
        paymentData.machinePic,
        paymentData.machineTypeLutCkey
    );
    paymentData.machineTypeLutImage;
    //if (transactionInfo.currencyCode && transactionInfo.currencyCode > 1) {
    transactionInfo.currencyCode = paymentData.currencyCode;
    //}
    if (paymentData.countryCode && paymentData.countryCode.length > 1) {
        transactionInfo.countryCode = paymentData.countryCode;
    }
    transactionInfo.token = paymentData.token;
};
function HandleMachinePic(machinePic: string, machineTypeLutCkey: string) {
    if (machinePic) return machinePic;
    else if (machineTypeLutCkey) return `${S3_PATH}${machineTypeLutCkey}.svg`;

    return `${S3_PATH}${GENERAL_MACHINE_COVER_IMAGE}.svg`;
}
