<template>
    <div id="g-recaptcha" class="g-recaptcha" :data-sitekey="sitekey"></div>
</template>

<script>
export default {
    data() {
    return {
            widgetId: 0,
        };
    },
    props: {
      siteKey: String,
    },
    methods: {
        execute() {
            window.grecaptcha.execute(this.widgetId);
        },
        reset() {
            window.grecaptcha.reset(this.widgetId);
        },
      render() {
            if (window.grecaptcha) {
              this.widgetId = window.grecaptcha.render('g-recaptcha', {
                sitekey: this.siteKey,

                    // the callback executed when the user solve the recaptcha
                    callback: (response) => {
                        // emit an event called verify with the response as payload
                        this.$emit('recaptchaCheckedFunc', response);
                        // reset the recaptcha widget so you can execute it again
                        this.reset();
                    },
                });
            }
        },
    },
    mounted() {
        // render the recaptcha widget when the component is mounted
        this.render();
    },
};
</script>
