<template>
    <div class="main">
        <div class="wrapper">
            <img src="//static.nayax.com/machine_Landing_page_asstes/success4.gif" />
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            img: String,
        },
    };
</script>
<style scoped>
    .main {
        text-align: left;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 99999;
    }
    .wrapper {
        box-sizing: border-box;
        position: absolute;
        right: 0;
        left: 0;
        margin: 0 auto;
        top: 20%;
        justify-content: center;
        display: flex;
        animation-name: fade_in;
        animation-duration: 1s;
    }
</style>
