<template>
    <span @click="getStarIndex" :class="{ star_active: isActive }" class="oi oi-star"></span>
</template>

<script>
    export default {
        // data: () => {empty:""},
        props: {
            isActive: Boolean,
            index: Number,
        },
        methods: {
            getStarIndex: function () {
                this.$emit('get-star-index', this.index);
            },
        },
    };
</script>
