<template>
    <div class="modal-mask" v-if="showRecaptchaModal" @close="showRecaptchaModal = false">
        <div class="modal-wrapper">
            <recaptcha
                class="modal-container"
                ref="recaptcha"
                @recaptchaCheckedFunc="recaptchChecked"
                :siteKey="this.scan2payStore.captchaSiteKey"
            ></recaptcha>
        </div>
    </div>
    <div>
        <EndPaymentSession v-if="showSessionEnd && !showsessionEndTimeout" />
        <Machine />
        <div id="search_area">
            <div class="row TicketArea" style="position: absolute">
                <div>
                    <label
                        class="ScanTicket-label d-flex justify-content-center"
                        style="margin-top: auto; direction: initial"
                    >
                        {{ $t('Enter: ') }}
                    </label>
                </div>
                <div>
                    <label class="enterTicket-label d-flex justify-content-center">
                        {{ $t(ticketNumPlaceHolder) }}
                    </label>
                </div>
            </div>
            <div class="row" style="margin-top: 30px">
                <div>
                    <input
                        id="ticketNumber"
                        v-bind:class="emptyText"
                        class="search-text input-search-text form-control py-2 border-right-0 border form-rounded"
                        type="text"
                        v-model="ticketNum"
                        maxlength="10"
                        @input="ticketNumberChanged()"
                    />
                </div>
            </div>
            <div class="row" style="width: 315px; height: 15px">
                <div class="cc_error_list_con" :style="{ opacity: validationError ? 1 : 0 }">
                    <transition name="slide-fade-up">
                        <span id="validationMsg">{{ $t(validationDefaultMsg) }}</span>
                    </transition>
                </div>
            </div>
            <div class="row">
                <button
                    id="searchBtn"
                    style="opacity: unset; border: none"
                    :disabled="searchDisabled"
                    class="bbtn btn credit_card_payment_btn search-btn"
                    @click="searchClick()"
                >
                    <h4 class="black_txt yellow-txt-btn" v-if="!isLoading">
                        {{ $t('Search') }}
                    </h4>
                    <img
                        v-if="isLoading"
                        src="@/assets/images/scan2pay/loader.gif"
                        class="loader"
                    />
                </button>
            </div>
            <div class="row" style="margin-top: -10px">
                <h2 class="hr-lines" style="font-size: 18px; font-weight: bold">
                    {{ $t('or') }}
                </h2>
            </div>
            <div style="margin-top: -10px">
                <div class="row ScanTicket-label d-flex justify-content-center">
                    <tr class="col-12">
                        {{
                            $t('Scan ticket QR')
                        }}
                    </tr>
                </div>
                <div
                    class="input-group-text bg-transparent form-rounded"
                    style="border: 0 transparent; padding: 0rem 2rem; margin-top: -10px"
                >
                    <img
                        @click="$refs.qrScannerModal.openCameraAndShowModal()"
                        class="qr-icon"
                        src="../../assets/images/QR.svg"
                    />
                </div>
            </div>
            <QrScannerModal ref="qrScannerModal" @onDecode="handleOnDecode" />
        </div>
    </div>
</template>

<script>
import Machine from '../../components/Scan2Pay/Machine.vue';
import QrScannerModal from '../../components/Scan2Pay/QrScannerModal.vue';
import EndPaymentSession from '../../components/Scan2Pay/EndPaymentSession.vue';
import ApiClient from '../../utils/apiClient';
import useQrStore from '../../store/qr';
import useScan2payStore from '../../store/scan2pay';
import usePaymentSessionStore from '../../store/paymentSession';
import { isProxy, toRaw } from 'vue';
import Recaptcha from '../../components/common/Recaptcha.vue';

export default {
    name: 'SearchView',
    components: {
        Machine,
        QrScannerModal,
        EndPaymentSession,
        Recaptcha,
    },
    setup() {
        const scan2payStore = useScan2payStore();
        const qrStore = useQrStore();
        const paymentSessionStore = usePaymentSessionStore();
        return { scan2payStore, qrStore, paymentSessionStore };
    },
    data() {
        return {
            showsessionEndTimeout: false,
            showSessionEnd: false,
            ticketNum: '',
            showModal: false,
            camera: 'off',
            validationError: false,
            validationDefaultMsg: 'Ticket not found',
            isLoading: false,
            scanTicket: '',
            recaptchaToken: '',
            showRecaptchaModal: false,
        };
    },
    mounted() {
        if (this.paymentSessionStore.paymentSessionInfo.session) {
            if (this.paymentSessionStore.paymentSessionInfo.session.mode === 'SessionEnd') {
                this.showSessionEnd = true;
                this.ticketNum = this.scan2payStore.scan2payInfo.ticketNum;
            }
        }
        if (this.$route.query.credential) {
            this.ticketNum = this.$route.query.credential;
        }

        window.sessionStorage.setItem('sessionCaptchaKey', this.scan2payStore.sessionCaptchaKey);
    },
    methods: {
        recaptchChecked(response) {
            this.recaptchaToken = response;
            this.showRecaptchaModal = false;
        },
        searchClick() {
            var ticketNumber;
            if (this.ticketNum === '') ticketNumber = this.scanTicket;
            else ticketNumber = this.ticketNum;
            //console.log(ticketNumber);

            //console.log(this.ticketNum, this.$route.params.staticQr);
            this.isLoading = true;
            this.validationError = false;

            window.sessionStorage.setItem('staticQr', this.$route.params.staticQr);
            window.sessionStorage.setItem('credential', ticketNumber);

            var sessionCaptchaKey = window.sessionStorage.getItem('sessionCaptchaKey');
            var ticketFeeRequestModel = {
                StaticQr: this.$route.params.staticQr,
                Credential: ticketNumber,
                recaptchaToken: this.recaptchaToken,
                machineId: this.scan2payStore.machineId,
                captchaKey: sessionCaptchaKey,
            };
            var self = this;
            var paymentDetailsPromise =
                ApiClient.Scan2Pay.getTicketFeeDetails(ticketFeeRequestModel);
            paymentDetailsPromise
                .then(function (result) {
                    var paymentDetails = result.data;

                    const paymentSession = {
                        mode: 'Start',
                        time: paymentDetails.paymentSessionTime,
                    };

                    const scan2pay = {
                        qrCode: self.$route.params.staticQr,
                        ticketNum: ticketNumber,
                        paymentDetails: paymentDetails,
                        //paymentSession: paymentSession,
                    };

                    var scan2payStore = self.scan2payStore;
                    if (isProxy(self.scan2payStore)) {
                        scan2payStore = toRaw(self.scan2payStore);
                    }
                    scan2payStore.setScan2pay(scan2pay);

                    self.qrStore.setPaymentData(paymentDetails.transactionInfoModel);

                    if (paymentDetails.totalPayment === 0) {
                        ApiClient.Scan2Pay.transactionByTicket(
                            scan2pay.ticketNum,
                            paymentDetails.transactionInfoModel.machineId
                        ).then((res) => {
                            var model = res.data;
                            window.sessionStorage.setItem(
                                'localTimeToExitStr',
                                model.localTimeToExitStr
                            );

                            self.$router.push({ name: 'NoPayment' });
                        });
                    } else {
                        self.paymentSessionStore.setPaymentSession(paymentSession);
                        self.$router.push({ name: 'PaymentDetails' });
                    }

                    if (self.recaptchaToken) self.recaptchaToken = null;
                })
                .catch(function (e) {
                    console.log(e);
                    if (e.response && e.response.data) {
                        if (e.response.data.error == 'need captcha') self.showRecaptchaModal = true;

                        if (e.response.data.error == 'Captcha is not valid') {
                            self.$router.push({
                                name: 'error',
                                params: {
                                    type: 'recaptcha',
                                },
                            });
                        }
                    }
                    self.recaptchaToken = null;
                    self.validationError = true;
                    self.isLoading = false;
                });
        },
        handleOnDecode(decodedString) {
            //console.log(decodedString);
            //var startIndex = 7;
            //var endIndex = 16;
            //this.ticketNum = decodedString; //decodedString.substring(startIndex, endIndex);
            //this.isScanMode = true;
            this.scanTicket = decodedString;
            this.ticketNum = '';

            this.searchClick();
        },
        ticketNumberChanged() {
            if (!this.validationError) return;
            this.validationError = false;
        },
    },
    computed: {
        searchDisabled() {
            return this.ticketNum === '';
        },
        emptyText() {
            var className = this.ticketNum === '' ? 'emptyText' : '';
            return className;
        },
        ticketNumPlaceHolder() {
            var placeHolder = '';
            if (this.scan2payStore.parkingIdentifiers === null) return '';
            for (let index = 0; index < this.scan2payStore.parkingIdentifiers.length; index++) {
                const element = this.scan2payStore.parkingIdentifiers[index];
                var elemntTranslated = this.$t(element);
                placeHolder += elemntTranslated + ' / ';
            }
            //remove the last ' / '
            return placeHolder.slice(0, -3);
        },
    },
};
</script>

<style scoped>
:deep(.modal-container) {
    display: flex;
    justify-content: center;
    align-items: center;
}

:deep(.modal-content) {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
}

.modal__title {
    font-size: 1.5rem;
    font-weight: 700;
}

hr.style-four {
    height: 12px;
    border: 0;
    box-shadow: inset 0 12px 12px -12px rgba(0, 0, 0, 0.5);
}

.search-btn {
    background-color: #ffcd00;
    margin-bottom: 80px;
    margin: 10px auto 15px;
    border-radius: 30px;
    width: 315px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search-btn h4 {
    margin: 0;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 10px;
    /*width: calc(100% - 110px);*/
    font-size: 18px;
    color: #000000;
}

[dir='rtl'] .search-btn h4 {
    margin-right: 10px;
}

.search-btn:disabled {
    background-color: #e0e0e0;
    border: transparent;
}

.loader {
    border: 2px solid #ffcd00;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    /*  -webkit-animation: spin-538e4e3f 2s linear infinite;
    animation: spin-538e4e3f 2s linear infinite;*/
}

.yellow-txt-btn {
    width: calc(100% - 25px);
}

.search-btn:disabled h4 {
    color: #ffffff;
}

.emptyText {
    font-size: 15px !important;
    font-weight: 400 !important;
}

.search-input-warpper {
    width: 100%;
    text-align: center;
}

.search-input {
    height: 50px;
    background: #f7f7f7;
    text-align: center;
}

.search-text {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.16px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #262626;
}

.input-search-text {
    height: 50px;
    width: 315px;
    left: 30px;
    top: 356px;
    box-sizing: border-box;
    background-color: rgb(247, 247, 247);
    border-radius: 275px !important;
    border: 1px solid #979797 !important;
}

.search-label {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.16px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #262626;
    margin-top: 20px;
}

.enterTicket-label {
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.16px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #979797;
    margin-left: 10px;
    text-align: right;
    align-items: center;
    font-size: 11px;
}

[dir='rtl'] .enterTicket-label {
    margin-right: 10px;
}

.ScanTicket-label {
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.16px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #262626;
    text-align: center;
    font-weight: bold;
    font-size: 11px;
}

.cc_error_list_con {
    padding: 1px 20px;
    font-size: 10px;
    height: 20px;
    background: #ffeded;
    border-radius: 40px;
    margin: 2px;
}

.cc_error_list_con span {
    font-size: 0.8rem;
    left: 10px;
    color: #aa0000;
}

.input-group-append {
    height: 50px;
    border-radius: 275px;
    border: 1px solid #979797 !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-color: #979797 #979797 #979797 transparent !important;
}

.cc_error_list_con ul {
    padding-left: 15px;
}

.cc_error_list_con {
    max-width: 160px;
}

.form-rounded {
    border-radius: 1rem;
}

.hr-lines {
    max-width: 500px;
    margin: 10px auto;
    text-align: center;
}

.hr-lines:before {
    content: ' ';
    height: 1px;
    width: 120px;
    background: #c4c4c4;
    display: block;
    position: absolute;
    top: 180px;
    left: 40px;
}

.hr-lines:after {
    content: ' ';
    height: 1px;
    width: 120px;
    background: #c4c4c4;
    display: block;
    position: absolute;
    top: 180px;
    right: 40px;
}

#search_area {
    position: relative;
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    background-color: #fff;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

.dark-mode div:deep(.modal-content) {
    border-color: #2d3748;
    background-color: #1a202c;
}

.TicketArea {
    text-align: left;
    left: 60px;
}

[dir='rtl'] .TicketArea {
    text-align: right;
    right: 60px;
}
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: fit-content;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}
</style>
