<template>
    <div class="sessionBox sticky-top" v-if="show">
        <span class="sessionText">
            {{ $t('You have') }}
            {{ minutes }}:{{ seconds }}
            {{ $t('min to complete the payment') }}
        </span>
    </div>
    <!-- https://stackoverflow.com/questions/55316490/vue-best-practice-for-calling-a-method-in-a-child-component -->
    <!-- https://dev.to/viniciuskneves/watch-for-vuex-state-changes-2mgj -->
    <!-- https://codesandbox.io/s/agitated-haze-ojdun?file=/src/App.vue -->
</template>

<script setup>
    //import useStore from '../../store';
    import useScan2payStore from '../../store/scan2pay';
    import usePaymentSessionStore from '../../store/paymentSession';
    import { useRouter } from 'vue-router';
    import { ref } from 'vue';
    //import { mapState } from 'pinia';

    const minutes = ref(0);
    const seconds = ref(0);
    const show = ref(false);
    const timerIsOn = ref(false);
    let intervalId = null;
    let scan2payStore = useScan2payStore();
    let paymentSessionStore = usePaymentSessionStore();
    const router = useRouter();

    //var self = this;
    paymentSessionStore.$subscribe((mutation, state) => {
        var session = paymentSessionStore.paymentSessionInfo.session;
        if (session) {
            var mode = session.mode;
            var timeDuration = session.time;
            console.log(mode);
            if (mode === 'Start' && !timerIsOn.value) {
                var endTime = new Date();
                endTime.setSeconds(endTime.getSeconds() + timeDuration);
                startTimer(endTime);
                timerIsOn.value = true;
                setTimeout(() => {
                    show.value = true;
                }, 1000);
            } else if (mode === 'Stop') {
                timerIsOn.value = false;
                show.value = false;
                clearInterval(intervalId);
            }
        }
    });

    //methods
    function startTimer(endTime) {
        intervalId = setInterval(() => {
            var now = new Date();
            var diff = endTime - now;
            minutes.value = Math.floor(diff / 60000);
            seconds.value = ((diff % 60000) / 1000).toFixed(0);
            minutes.value = minutes.value < 10 ? `0${minutes.value}` : minutes.value;
            seconds.value = seconds.value < 10 ? `0${seconds.value}` : seconds.value;
            if (diff < 0) {
                show.value = false;
                timerIsOn.value = false;
                clearInterval(intervalId);
                let session = paymentSessionStore.paymentSessionInfo;
                session.mode = 'SessionEnd';
                session.time = 0;
                paymentSessionStore.setPaymentSession(session);
                const qrCode = scan2payStore.scan2payInfo.qrCode;
                scan2payStore.scan2payInfo.ticketNum = null;
                 router.push({
                    name: 'Search',
                    params: { staticQr: qrCode },

                });
               
            }
        }, 1000);
    }
</script>

<style scoped>
    .sessionBox {
        background-color: #007aff;
        height: 50px;
    }

    .sessionText {
        padding-top: 16px;

        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        /* or 167% */

        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        letter-spacing: 0.16px;
        font-feature-settings: 'pnum' on, 'lnum' on;

        color: #ffffff;
    }
</style>
