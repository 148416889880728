<template>
    <div>
        <vue-final-modal
            v-model="showModal"
            classes="modal-container"
            content-class="modal-content"
            @click-outside="onClickOutside"
        >
            <span class="modal__title" v-show="showCamera">{{ $t('Please Scan') }}</span>
            <span class="modal__title" v-show="!showCamera">{{ $t('Waiting for Camera') }}</span>
            <qrcode-stream
                @decode="onDecode"
                @init="onInit"
                :camera="camera"
                v-show="showCamera"
            ></qrcode-stream>
        </vue-final-modal>

        <!-- <button @click="openCameraAndShowModal()">Open Camera</button> -->
    </div>
</template>
<script>
    import { QrcodeStream } from 'vue3-qrcode-reader';
    import { VueFinalModal } from 'vue-final-modal';

    export default {
        name: 'QrScannerModal',
        components: {
            QrcodeStream,
            VueFinalModal,
        },
        /*props: {
    qrModalScannerSetup: Object,
  },*/
        data() {
            return {
                showModal: false,
                camera: 'off',
                showCamera: false,
            };
        },
        methods: {
            openCameraAndShowModal() {
                this.showModal = true;
                this.camera = 'auto';
            },
            onClickOutside() {
                // console.log('aaa');
                // this.showModal = false;
                this.camera = 'off';
            },
            async onInit(promise) {
                // show loading indicator
                this.showCamera = false;
                try {
                    const { capabilities } = await promise;

                    // successfully initialized
                } catch (error) {
                    if (error.name === 'NotAllowedError') {
                        // user denied camera access permisson
                    } else if (error.name === 'NotFoundError') {
                        // no suitable camera device installed
                    } else if (error.name === 'NotSupportedError') {
                        // page is not served over HTTPS (or localhost)
                    } else if (error.name === 'NotReadableError') {
                        // maybe camera is already in use
                    } else if (error.name === 'OverconstrainedError') {
                        // did you requested the front camera although there is none?
                    } else if (error.name === 'StreamApiNotSupportedError') {
                        // browser seems to be lacking features
                    }
                } finally {
                    // hide loading indicator
                    this.showCamera = true;
                }
            },

            onDecode(decodedString) {
                // console.log(decodedString);
                this.showModal = false;
                this.camera = 'off';

                this.$emit('onDecode', decodedString);
            },
        },
    };
</script>

<style></style>
