import { BasicTransactionInfo } from '@/models/landingPage';
import { ApplePayConfiguration } from '@/typings/paymentMethods';
import ApiClient from '../utils/apiClient';

export default class AppleClient {
    private readonly _settings: ApplePayConfiguration;
    private readonly _machineName: string;

    constructor(settings: ApplePayConfiguration, machineName: string) {
        this._settings = { ...settings };
        this._machineName = machineName;
    }

    public static isSupported = () =>
        window.ApplePaySession !== undefined && ApplePaySession.canMakePayments();

    private _handleApplePayEvents = (
        appleSession: ApplePaySession,
        paymentName: string,
        onSuccess: (a: string) => Promise<any>
    ) => {
        appleSession.onvalidatemerchant = function (event) {
            console.log(event.validationURL);
            ApiClient.apple.validateMerchant(event.validationURL, paymentName).then((response) => {
                try {
                    console.log('Completing merchant validation');
                    appleSession.completeMerchantValidation(response.data);
                } catch (error) {
                    console.log('Couldnt complete merchant validation due ' + error);
                }
            });
        };

        appleSession.onpaymentauthorized = function (event) {
            console.log('PAYMENT AUTHORIZED!');

            onSuccess(JSON.stringify(event.payment.token.paymentData)).then(function (result) {
                if (result) {
                    appleSession.completePayment(ApplePaySession.STATUS_SUCCESS);
                    console.log(result);
                } else {
                    appleSession.completePayment(ApplePaySession.STATUS_FAILURE);
                    console.log(result);
                }
            });
        };

        appleSession.oncancel = function (event) {
            console.log('PAYMENT CANBCELED!');
            console.log(event);
        };
        // appleSession.onpaymentmethodselected = function (event) {
        //     console.log('PAYMENT onpaymentmethodselected!');
        //     console.log(event);
        // };
    };
    private _jsPay = async (
        transactionInfo: BasicTransactionInfo,
        onSuccess: (a: any) => Promise<any>,
        paymentName: string
    ) => {
        const paymentRequest: ApplePayJS.ApplePayPaymentRequest = {
            countryCode: transactionInfo.countryCode,
            currencyCode: transactionInfo.currencyCode,
            merchantCapabilities: [...this._settings.merchantCapabilities],
            supportedNetworks: [...this._settings.supportedNetworks],
            total: {
                label: this._machineName,
                type: 'final',
                amount: transactionInfo.totalPrice.toString(),
            },
        };
        console.log(paymentRequest);
        const applePaySession = new ApplePaySession(4, paymentRequest);
        this._handleApplePayEvents(applePaySession, paymentName, onSuccess);
        applePaySession.begin();
    };
    public pay = async (
        transactionInfo: BasicTransactionInfo,
        onSuccess: (a: any) => Promise<any>,
        paymentName: string
    ) => {
        // let applePayMethod = {
        //     supportedMethods: 'https://apple.com/apple-pay',
        //     data: {
        //         merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit'],
        //         countryCode: transactionInfo.countryCode,
        //     },
        // };

        // let details = {
        //     total: {
        //         label: 'Landing Page',
        //         amount: {
        //             value: transactionInfo.value,
        //             currency: transactionInfo.currencyCode,
        //         },
        //     },
        // };
        // let options = {
        //     requestPayerName: false,
        //     requestBillingAddress: false,
        //     requestPayerEmail: false,
        //     requestPayerPhone: false,
        //     requestShipping: false,
        // };

        ApplePaySession.canMakePaymentsWithActiveCard('merchant.com.nayax.pay').then((result) =>
            console.log('Can make payments: const canMakePayment = ' + result)
        );
        await this._jsPay(transactionInfo, onSuccess, paymentName);

        // return;
        // if (window.PaymentRequest) {
        //     console.log('applePayMethod: ' + applePayMethod);
        //     console.log('details: ' + details);
        //     var request = new PaymentRequest([applePayMethod], details, options);
        //     console.log('request: ' + request);
        //     request.onmerchantvalidation = function (event) {
        //         // Have your server fetch a payment session from event.validationURL.
        //         if (event) console.log('Merchant validation:' + event.validationURL);
        //         else console.log('Merchant validation event is NULL');
        //         console.log('fetchPaymentSession: start ' + event.validationURL);
        //         const sessionPromise = axios.post(event.validationURL);
        //         event.complete(sessionPromise);
        //     };

        //     var canPay = await request.canMakePayment();
        //     console.log('Can pay: ' + canPay);
        //     try {
        //         var response = await request.show();
        //         console.log('response: ' + response);
        //         // alert('response ' + response)
        //         onSuccess(response);

        //         // Process response here, including sending payment instrument
        //         // (e.g., credit card) information to the server.
        //         // paymentResponse.methodName contains the selected payment method
        //         // paymentResponse.details contains a payment method specific response
        //         await response.complete('success');
        //     } catch (err) {
        //         console.log(err);
        //         alert(
        //             'Apple ERROR ' +
        //                 err.message +
        //                 ',' +
        //                 err.name +
        //                 ',' +
        //                 err.lineNumber +
        //                 ',' +
        //                 err.stack
        //         );
        //         // onSuccess(err);
        //     }
        // } else {
        //     // TODO: consider using Apple Pay JS instead....
        //     console.log('consider using Apple Pay JS instead....');
        // }
    };
}
