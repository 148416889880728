export enum Steps {
    init = 0,
    start = 1,
    creditCard = 2,
    apmPay = 4,
    paymentFail = 5,
    paymentSuccess = 6,
    rating = 6,
    ereceipt = 7,
    finish = 8,
}
