export default {
    back: 'חזור',
    'session time expired': 'פג תוקף המסך',
    'you have': 'יש לך',
    'min to complete the payment': 'דקות לסיום התשלום',
    'ticket #': "מס' כרטיס",
    Ticket: "מס' כרטיס",
    Phone: "מס' טלפון",
    'License Plate': ' לוחית רישוי',
    'ticket number': "מס' כרטיס",
    'Enter: ': ':נא להזין',
    'Scan ticket QR': 'לסרוק קוד QR',
    'total payment': 'תשלום כולל',
    'please proceed to exit': 'המשך ליציאה',
    'no payment required': 'אין צורך בתשלום',
    'exit by': 'יש לצאת עד',
    'exit not later than': 'יש לצאת עד',
    'payment receipt': 'חשבונית תשלום',
    location: 'מיקום',
    'transaction id': "מס' עסקה",
    net: 'סכום לתשלום',
    discount: 'הנחה',
    save: 'לשמור',
    send: 'לשלוח',
    'credit card': 'כרטיס אשראי',
    machine: 'מכונה',
    ratings: 'דירוג',
    'your product': 'המוצר שלך',
    'your products': 'המוצרים שלך',
    'selection id': 'קוד המוצר',
    price: 'מחיר',
    total: 'סה"כ',
    or: 'או',
    'card details': 'פרטי כרטיס',
    'terms & conditions': 'תנאי שימוש',
    support: 'תמיכה',
    '&copy; all rights reserved': '&copy; כל הזכויות שמורות',
    'error occurred': 'קרתה תקלה',
    'please approve gpay payment': 'אשר תשלום GPay',
    'please approve apple payment': 'אשר תשלום Apple',
    'successful payment': 'העסקה אושרה',
    'please collect your item at the machine': 'ניתן לאסוף את המוצר מהמכונה',
    'how would you rate': 'כיצד תוכלו לדרג',
    'your experience?': 'את חוויית הרכישה במכונה?',
    'share details of your experience with the machine': 'שתפו את משוב חוויית הרכישה במכונה',
    'share feedback': 'שתפו את המשוב',
    'skip to receipt': 'דילוג אל הקבלה',
    gpay: 'Gpay',
    'apple pay': 'Apple Pay',
    'date/time': 'תאריך',
    vat: 'VAT',
    'total price': 'סה"כ',
    'send email': 'המייל נשלח',
    expire: 'תוקף',
    cvv: 'CVV',
    confirm: 'אישור',
    refresh: 'רענן',
    '{n} digits required': 'חובה {n} ספרות',
    'invalid expiration': 'תוקף אינו תקין',
    required: 'שדה חובה',
    'invalid card number': 'מספר כרטיס אינו תקין',
    'at least {n} letters are required': 'חובה לפחות {n} אותיות',
    'first and last names are required': 'חובה למלא שם פרטי ושם משפחה',
    'pay with': 'שלם עם',
    'please enter:': 'אנא הכנס/י',
    'license plate number': 'מספר לוחית רישוי',
    'phone number': 'מספר טלפון',
    search: 'חיפוש',
    'contact us': 'צור קשר',
    'all rights reserved': 'כל הזכויות שמורות',
    entry: 'שעת כניסה',
    'duration(hours)': 'זמן שהייה',
    duration: 'זמן שהייה',
    'Credit Card': 'כרטיס אשראי',
    'cardholder name': 'שם בעל הכרטיס',
    'please scan': 'סרוק בבקשה',
    'waiting for camera': 'ממתין למצלמה',
    'exit no later than:': 'יש לצאת מהחניון עד',
    'additional fees may apply': 'ייתכן ויגבו עמלות נוספות',
    tax: 'מע"מ',
    'Ticket not found': 'הכרטיס לא נמצא',
    'We use the necessary cookies to make the site work and for security purposes. For more details: ':
        'באתר זה נעשה שימוש בקבצי עוגיות (cookies) וטכנולוגיות דומות לצורך הפעלת האתר ואבטחתו. למידע נוסף ראה את',
    'We seem to be having some issues with the payment method you chose. Please try again.':
        'לא ניתן לבצע את התשלום. אנא נסה שוב.',
    'I understand': 'הבנתי',
    'privacy policy': 'מדיניות הפרטיות',
    d: 'ימים ',
    h: " שע'",
    m: " דק'",
    'The email has been sent': 'הקבלה נשלחה בהצלחה',
    'Invalid Email Address': 'אימייל שגוי',
};
