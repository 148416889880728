<template>
    <div class="card-form" id="card-form" style="font-weight: bold">
        <label>{{ $t('Card Details') }}</label>
        <div class="cc_error_list_con" :style="{ opacity: isErrsInList ? 1 : 0 }" style="top: 0">
            <transition name="slide-fade-up">
                <ol>
                    <li v-for="(item, $index) in validationErrs" :key="$index">
                        {{ item }}
                    </li>
                    <li v-if="validationErrs && validationErrs.length > 0">
                        {{ validationDefaultMsg }}
                    </li>
                </ol>
            </transition>
        </div>
        <!-- header text -->
        <!-- list of cards images according to brand -->
        <div class="card-list" :style="{ marginTop: isErrsInList ? '30px' : '0px' }">
            <div class="card-item__side -front">
                <div
                    class="card-item__focus"
                    v-bind:class="{ '-active': focusElementStyle }"
                    v-bind:style="focusElementStyle"
                    ref="focusElement"
                ></div>
                <div class="card-item__cover">
                    <img v-if="getCardType" :src="getImage('background')" class="card-item__bg" />
                </div>

                <div class="card-item__wrapper" :class="[getCardType ? 'white_txt' : 'gray_txt']">
                    <!-- 1. card number -->
                    <label for="cardNumber" class="card-item__number" ref="cardNumber">
                        <label for="cardNumber" class="card-item__numberTitle"></label>
                        <div class="card-input">
                            <input
                                type="text"
                                inputmode="numeric"
                                @keyup="handleInput"
                                id="cardNumber"
                                class="card-input__input"
                                @keypress="handleKeyPress"
                                v-maska="generateCardNumberMask"
                                v-model="cardNumber"
                                v-on:focus="focusInput"
                                v-on:blur="blurInput"
                                data-ref="cardNumber"
                                ref="cardNumberInput"
                                autocomplete="cc-number"
                            />

                            <div
                                v-if="getCardType == 'amex'"
                                class="input_blanks_con"
                                :style="{
                                    'border-color': getCardType ? 'white' : '#979797',
                                }"
                            >
                                <span v-for="(n, $index) in amexCardMask" :key="$index">
                                    <div v-if="n == '#'" class="card_line"></div>
                                    <div v-else class="card_blank"></div>
                                </span>
                            </div>
                            <div
                                v-else-if="getCardType == 'diners'"
                                class="input_blanks_con"
                                :style="{
                                    'border-color': getCardType ? 'white' : '#979797',
                                }"
                            >
                                <span v-for="(n, $index) in dinersCardMask" :key="$index">
                                    <div v-if="n == '#'" class="card_line"></div>
                                    <div v-else class="card_blank"></div>
                                </span>
                            </div>
                            <div
                                v-else
                                class="input_blanks_con"
                                :style="{
                                    'border-color': getCardType ? 'white' : '#979797',
                                }"
                            >
                                <span v-for="(n, $index) in otherCardMask" :key="$index">
                                    <div v-if="n == '#'" class="card_line"></div>
                                    <div v-else class="card_blank"></div>
                                </span>
                            </div>
                        </div>
                    </label>
                    <div class="credit_card_details_row">
                        <!-- 2. chip -->
                        <div class="card-item__top">
                            <img
                                src="../../assets/CreditCardForm/images/Chip.png"
                                class="card-item__chip"
                            />
                        </div>

                        <!-- 3. expire -->
                        <div class="card-item__date" ref="cardExpire">
                            <label for="cardExpire" class="card-item__dateTitle">
                                {{ $t('Expire') }}
                            </label>
                            <div
                                class="card-input_expire_date"
                                :style="{
                                    background: getCardType ? 'none' : 'white',
                                }"
                            >
                                <div class="card-input">
                                    <input
                                        type="text"
                                        @keyup="handleInput"
                                        inputmode="numeric"
                                        v-maska="'##/##'"
                                        @keypress="handleKeyPress"
                                        @change="handleInput"
                                        class="card-input__input"
                                        id="cardExpire"
                                        :class="[getCardType ? 'white_txt' : 'gray_txt']"
                                        maxlength="5"
                                        v-model="cardExpire"
                                        v-on:focus="focusInput"
                                        v-on:change="blurInput"
                                        v-bind:placeholder="$t('MM/YY')"
                                        data-ref="cardExpire"
                                        ref="cardExpireInput"
                                        autocomplete="off"
                                    />
                                </div>
                            </div>
                        </div>

                        <!-- 4. cvv -->
                        <div class="card-item__cvv" ref="cardCvv">
                            <div class="card-item__cvvTitle">
                                {{ $t('CVV') }}
                            </div>
                            <div class="card-input">
                                <input
                                    type="text"
                                    @keyup="handleInput"
                                    inputmode="numeric"
                                    @change="handleInput"
                                    @keypress="handleKeyPress"
                                    class="card-input__input gray_txt"
                                    style="font-size: 17px !important"
                                    id="cardCvv"
                                    :class="[getCardType ? 'white_txt' : 'gray_txt']"
                                    :placeholder="$t('CVV')"
                                    maxlength="3"
                                    v-model="cardCvv"
                                    v-on:focus="focusInput"
                                    v-on:blur="blurInput"
                                    :style="{
                                        background: getCardType ? 'none' : 'white',
                                    }"
                                    data-ref="cardCvv"
                                    ref="cardCvvInput"
                                    autocomplete="off"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="credit_card_details_row align-items_center">
                        <!-- 5. card holder -->
                        <div class="card-item__content" ref="cardName">
                            <label for="cardName" class="card-item__info">
                                <transition name="slide-fade-up">
                                    <div class="card-input">
                                        <input
                                            @change="handleInput"
                                            @keyup="handleInput"
                                            type="text"
                                            id="cardName"
                                            class="card-input__input"
                                            v-on:focus="focusInput"
                                            v-on:blur="blurInput"
                                            v-model="cardName"
                                            :placeholder="$t('cardholder name')"
                                            data-ref="cardName"
                                            :class="[getCardType ? 'white_txt' : 'gray_txt']"
                                            :style="{
                                                background: getCardType ? 'none' : 'white',
                                            }"
                                            ref="cardNameInput"
                                            autocomplete="off"
                                        />
                                    </div>
                                </transition>
                            </label>
                        </div>
                        <div class="card-item__type" :class="['card_brand_' + getCardType]">
                            <transition name="slide-fade-up">
                                <img
                                    v-if="getCardType && getCardType != 'generic'"
                                    :src="getImage('logo')"
                                    v-bind:key="getCardType"
                                    alt=""
                                    class="card-item__typeImg"
                                />
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- card form bottom -->
        <div class="card-form__inner">
          <button id="confirmBtn"
                  @click="creditCardSubmitAction"
                  class="bbtn card-form__button"
                  :class="[isAllFieldsValid ? '-active' : '']"
                  :disabled="isPayProccess">
                  <h4 class="yellow-txt-btn" v-if="!isLoading" style="font-size: 1.15rem;">
              {{ $t('Confirm') }}
            </h4>
            <img v-if="isLoading"
                 src="@/assets/images/scan2pay/loader.gif"
                 class="loader" />
          </button>
        </div>
    </div>
</template>

<script>
    import { isValidExp, isValidCvv, isValidCard } from '../../utils/ccValidation';
    export default {
    data() {
            
            var self = this;
            return {
                isLoading: false,
                cardName: '',
                cardNumber: '',
                cardExpire: '',
                cardCvv: '',
                minCardYear: new Date().getFullYear(),
                amexCardMask: '#### ###### #####',
                dinersCardMask: '#### ###### ####',
                otherCardMask: '#### #### #### ####',
                cardNumberTemp: '',
                isCardFlipped: false,
                focusElementStyle: null,
                isInputFocused: false,
                validationState: {
                    cardNumber: '',
                    cardExpire: '',
                    cardCvv: '',
                    cardName: '',
                },
                validationDefaultMsg: self.$t('One or more of the input fields is invalid'),
                validationFncs: {
                    cardName: [
                        (v) => !!v || self.$t('Required'),
                        (v) =>
                            v.length >= 5 ||
                            self.$t('At least {n} letters are required').replace('{n}', 5),
                        /*(v) =>
                            (v.split(' ').length > 1 &&
                                v.split(' ')[0].length > 1 &&
                                v.split(' ')[1].length > 1) ||
                            ccValidationTexts.requireSpace,*/
                    ],
                    cardNumber: [
                        (v) => !!v || self.$t('Required'),
                        (v) => isValidCard(v) || self.$t('Invalid Card Number'),
                        (v) =>
                            self.generateCardNumberMask.replaceAll(' ', '').length ===
                            v.replaceAll(' ', '').length,
                    ],
                    cardExpire: [
                        (v) => !!v || self.$t('Required'),
                        (v) => isValidExp(v) || self.$t('Invalid expiration'),
                    ],
                    cardCvv: [
                        (v) => !!v || self.$t('Required'),
                        (v) => isValidCvv(v) || self.$t('{n} digits required').replace('{n}', 3),
                    ],
                },
            };
        },
        props: {
            isPayProccess: Boolean,
        },
        mounted() {
            this.cardNumberTemp = this.otherCardMask;
            document.getElementById('cardNumber').focus();
        },
        computed: {
            getCardType() {
                // https://stackoverflow.com/questions/9315647/regex-credit-card-number-tests

                let number = this.cardNumber;
                number = number.replace(/\s/g, '');
                if (!number) return false;
                let re = new RegExp('^4');
                if (number.match(re) != null) return 'visa';

                re = new RegExp('^(34|37)');
                if (number.match(re) != null) return 'amex';

                re = new RegExp('^5[1-5]');
                if (number.match(re) != null) return 'mastercard';

                re = new RegExp('^3(?:0[0-5]|[68][0-9])[0-9]{11}$');
                if (number.match(re) != null) return 'diners';

                re = new RegExp('^6011');
                if (number.match(re) != null) return 'discover';

                return 'generic'; // default type
            },
            generateCardNumberMask() {
                return this.getCardType === 'amex'
                    ? this.amexCardMask
                    : this.getCardType === 'diners'
                    ? this.dinersCardMask
                    : this.otherCardMask;
            },
            minCardMonth() {
                if (this.cardYear === this.minCardYear) return new Date().getMonth() + 1;
                return 1;
            },
            validationErrs() {
                var errArr = [];
                for (var prop in this.validationState) {
                    if (this.validationState[prop] !== '' && this.validationState[prop] !== true) {
                        // errArr.push(
                        //     this.metaText[prop + 'Label'] + ' : ' + this.validationState[prop]
                        // );
                    }
                }
                return errArr;
            },
            isErrsInList() {
                return this.validationErrs.length > 0;
            },
            isAllFieldsValid() {
                var data = this.validationState;
                var keys = Object.keys(data);
                return keys.every((v) => data[v] === true);
            },
            errToolTipPos() {
                var c = document.querySelector('.card-list');
                if (!c) return;
                var dim = c.getBoundingClientRect();
                var cBottom = dim.bottom + window.pageYOffset;
                return cBottom + 'px';
            },
        },
        watch: {
            cardYear() {
                if (this.cardMonth < this.minCardMonth) {
                    this.cardMonth = '';
                }
            },
        },
        methods: {
            getImage(type) {
                var images = require.context('../../assets/CreditCardForm', true, /\.png$/);
                var path = `./${type}/${type}_${this.getCardType}.png`;
                return images(path);
            },
          creditCardSubmitAction() {
            this.isLoading = true;
                var data = this.validateForConfirm();

            if (!this.isErrsInList && this.isAllFieldsValid) {

                    this.$emit('creditcard-submit', data);
                }
            },
            validateForConfirm() {
                var data = this.getCcObject();
                var keys = Object.keys(data);
                keys.forEach((v) => this.updateValidateList(v, data[v]));
                return data;
            },
            handleInput($e) {
                var self = this;
                var value = $e.target.value;
                var id = $e.target.id;
                if (this.validationHandle(id, value) === true) self.goToNextInput(id);

                this.updateValidateList(id, value);
            },
            updateValidateList(id, value) {
                var validate = this.validationHandle(id, value);
                this.validationState[id] = validate;
            },
            handleKeyPress(e) {
                if (e.charCode !== 13) return;

                this.goToNextInput(e.target.id);
            },
            goToNextInput(id) {
                var cardTypes = Object.keys(this.validationState);
                var pos = cardTypes.indexOf(id) + 1;
                if (cardTypes[pos]) {
                    var nextInput = cardTypes[pos] + 'Input';
                    this.$refs[nextInput].focus();
                    this.focusInput({
                        target: { dataset: { ref: cardTypes[pos] } },
                    });
                }
            },
            getCcObject() {
                const self = this;
                return {
                    cardName: self.cardName,
                    cardNumber: self.cardNumber,
                    cardExpire: self.cardExpire,
                    cardCvv: self.cardCvv,
                };
            },
            validationHandle(inputType, value) {
                const self = this;
                for (const v of self.validationFncs[inputType]) {
                    var validResult = v(value);
                    if (validResult !== true) return validResult;
                }
                return true;
            },
            focusInput(e) {
                this.isInputFocused = true;
                const targetRef = e.target.dataset.ref;
                const target = this.$refs[targetRef];
                this.focusElementStyle = {
                    width: `${target.offsetWidth}px`,
                    height: `${target.offsetHeight}px`,
                    transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`,
                };
            },
            blurInput() {
                const vm = this;
                setTimeout(() => {
                    if (!vm.isInputFocused) {
                        vm.focusElementStyle = null;
                    }
                }, 300);
                vm.isInputFocused = false;
            },
        },
    };
</script>
<style scoped>
    @media screen and (max-width: 700px), (max-height: 500px) {
        .wrapper {
            flex-wrap: wrap;
            flex-direction: column;
        }
    }

    .card-form {
        direction: ltr;
        margin: auto;
        max-width: 360px;
        display: flex;
        justify-content: center;
        justify-items: start;
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    .cc_error_list_con {
        max-width: 360px;
    }

    @media screen and (max-width: 576px) {
        .card-form {
            margin: 0 auto;
        }
    }

    .card-form__inner {
        background: #fff;
        border-radius: 10px;
        padding: 15px 0 0;
        padding-top: 140px;
    }

    .card-form__row {
        display: flex;
        align-items: flex-start;
    }

    @media screen and (max-width: 480px) {
        .card-form__row {
            flex-wrap: wrap;
        }
    }

    .card-form__col {
        flex: auto;
        margin-right: 35px;
    }

    .card-form__col:last-child {
        margin-right: 0;
    }

    @media screen and (max-width: 480px) {
        .card-form__col {
            margin-right: 0;
            flex: unset;
            width: 100%;
            margin-bottom: 20px;
        }

        .card-form__col:last-child {
            margin-bottom: 0;
        }
    }
    .loader {
        border: 2px solid #ffcd00;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        /*  -webkit-animation: spin-538e4e3f 2s linear infinite;
    animation: spin-538e4e3f 2s linear infinite;*/
    }

    .card-form__col.-cvv {
        max-width: 150px;
    }

    @media screen and (max-width: 480px) {
        .card-form__col.-cvv {
            max-width: initial;
        }
    }

    .card-form__group {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    .card-form__group .card-input__input {
        flex: 1;
        margin-right: 15px;
    }

    .card-form__group .card-input__input:last-child {
        margin-right: 0;
    }

    .card-form__button {
        width: 100%;
        height: 40px;
        background: #e0e0e0; /*#FFCD00 yellow*/
        border: none;
        border-radius: 30px;
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        margin-top: 4px;
        cursor: pointer;
    }

    @media screen and (max-width: 480px) {
        .card-form__button {
            margin-top: 10px;
        }
    }

    .card-item {
        max-width: 430px;
        height: 270px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        z-index: 2;
        width: 100%;
    }

    @media screen and (max-width: 480px) {
        .card-item {
            max-width: 310px;
            height: 220px;
            width: 90%;
        }
    }

    @media screen and (max-width: 360px) {
        .card-item {
            height: 180px;
        }
    }

    .card-item.-active .card-item__side.-front {
        transform: perspective(1000px) rotateY(180deg) rotateX(0deg) rotateZ(0deg);
    }

    .card-item.-active .card-item__side.-back {
        transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
    }

    .card-item__focus {
        position: absolute;
        z-index: 3;
        border-radius: 5px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
        opacity: 0;
        pointer-events: none;
        overflow: hidden;
        border: 2px solid rgba(255, 255, 255, 0.3);
    }

    .card-item__focus:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.3);
        height: 100%;
        border-radius: 5px;
        filter: blur(25px);
        opacity: 0.5;
    }

    .card-item__focus.-active {
        opacity: 1;
    }

    .card-item__side {
        border-radius: 15px;
        overflow: hidden;
        box-shadow: 0 3px 3px 0 rgb(0 0 0 / 30%);
        transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
        transform-style: preserve-3d;
        transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
        backface-visibility: hidden;
        height: 100%;
    }

    .card-item__side.-back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
        z-index: 2;
        padding: 0;
        height: 100%;
    }

    .card-item__side.-back .card-item__cover {
        transform: rotateY(-180deg);
    }

    .card-item__bg {
        display: block;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
    }

    .card-item__cover {
        height: 100%;
        /*background-color: #1c1d27;*/
        position: absolute;
        height: 100%;
        background-color: #f8f8f9;
        left: 0;
        top: 0;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
        box-shadow: 0 3px 3px 0 rgb(0 0 0 / 30%);
    }

    .card-item__cover:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        /*background: rgba(6, 2, 29, 0.45);*/
    }

    .card-item__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0px;
        padding: 0 10px;
    }

    .card-item__chip {
        width: 53px;
    }

    .card-item__type {
        height: 30px;
        position: relative;
        display: flex;
        justify-content: flex-end;
        max-width: 70px;
        margin-left: auto;
        width: 100%;
    }

    .card-item__typeImg {
        object-position: bottom right;
        margin-right: 9px;
        object-fit: contain;
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 70px;
    }

    .card_brand_visa img {
        max-height: 70%;
    }
    .card_brand_amex img {
        max-height: 70%;
    }
    .card_brand_diners img {
        max-height: 70%;
    }

    .card-item__type.card_brand_discover {
        margin-right: 15px;
        max-width: 50px;
    }

    .card_brand_discover img {
        max-height: 70%;
        max-width: 60px;
        position: absolute;
        top: 11px;
        right: 4px;
        margin-right: 0;
    }
    .card_brand_mastercard img {
        max-height: 100%;
    }

    .card-item__info {
        color: #fff;
        width: 100%;
        padding: 10px 15px;
        font-weight: 500;
        display: block;
        cursor: pointer;
    }

    @media screen and (max-width: 480px) {
        .card-item__info {
            padding: 10px 0 10px 10px;
        }
    }

    .card-item__holder {
        font-size: 13px;
        margin-bottom: 6px;
    }

    @media screen and (max-width: 480px) {
        .card-item__holder {
            font-size: 12px;
            margin-bottom: 5px;
        }
    }

    .card-item__wrapper {
        padding: 10px;
        position: relative;
        z-index: 4;
        height: 100%;
        user-select: none;
        max-width: 300px;
    }

    @media screen and (max-width: 480px) {
        .card-item__wrapper {
            padding: 10px;
        }
    }

    .card-item__wrapper label {
        text-transform: capitalize;
        margin-bottom: 0;
    }

    .credit_card_details_row label {
        padding-left: 0.3125rem;
    }

    .card-item__name {
        font-size: 18px;
        line-height: 1;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: uppercase;
    }

    @media screen and (max-width: 480px) {
        .card-item__name {
            font-size: 16px;
        }
    }

    .card-item__nameItem {
        display: inline-block;
        min-width: 8px;
        position: relative;
    }

    .card-item__number {
        font-weight: 500;
        line-height: 1;
        font-size: 27px;
        display: block;
        padding: 0px 15px;
        cursor: pointer;
        max-width: 16.5rem;
    }

    .card-item__numberItem {
        width: 16px;
        display: inline-block;
    }

    .card-item__numberItem.-active {
        width: 30px;
    }

    @media screen and (max-width: 480px) {
        .card-item__numberItem {
            width: 13px;
        }

        .card-item__numberItem.-active {
            width: 16px;
        }
    }

    @media screen and (max-width: 360px) {
        .card-item__numberItem {
            width: 12px;
        }

        .card-item__numberItem.-active {
            width: 8px;
        }
    }

    .card-item__content {
        color: #fff;
        display: flex;
        align-items: flex-start;
        width: 210px;
    }

    .card-item__date {
        flex-wrap: wrap;
        font-size: 18px;
        padding: 0 10px;
        display: inline-flex;
        width: 100px;
        white-space: nowrap;
        flex-shrink: 0;
        cursor: pointer;
        letter-spacing: 0.05rem;
    }

    .card-item__dateTitle {
        font-size: 13px;
        padding-bottom: 0px;
        width: 100%;
        margin-bottom: 0;
    }

    .card-item__band {
        background: rgba(0, 0, 19, 0.8);
        width: 100%;
        height: 50px;
        margin-top: 30px;
        position: relative;
        z-index: 2;
    }

    @media screen and (max-width: 480px) {
        .card-item__band {
            margin-top: 20px;
        }
    }

    @media screen and (max-width: 360px) {
        .card-item__band {
            height: 40px;
            margin-top: 10px;
        }
    }

    .card-item__cvv {
        text-align: left;
        position: relative;
        z-index: 2;
        padding: 0px 15px;
        max-width: 33%;
    }

    .card-item__cvv .card-item__type {
        opacity: 0.7;
    }

    .card-item__cvvTitle {
        padding-right: 10px;
        font-size: 13px;
        font-weight: 400;
        padding-left: 0.3125rem;
    }

    .card-item__cvvTitle label {
        text-transform: uppercase;
    }

    .card-item__cvvBand {
        height: 30px;
        background: #fff;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px;
        color: #1a3b5d;
        font-size: 24px;
        border-radius: 4px;
        margin-bottom: 0px;
    }

    .card-list {
        margin-bottom: -130px;
        min-width: 315px;
        z-index: 2;
    }

    @media screen and (max-width: 480px) {
        .card-list {
            margin-bottom: -120px;
        }
    }

    .card-input {
        margin-bottom: 10px;
        position: relative;
    }

    .card-input__label {
        font-size: 14px;
        margin-bottom: 5px;
        font-weight: 500;
        color: #262626;
        width: 100%;
        display: block;
        user-select: none;
    }

    .card-input__input {
        width: 100%;
        height: 40px;
        border-radius: 5px;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        font-size: 1.03rem;
        padding: 0.3125rem;
        background: none;
        border: 0px solid #000000;
        outline: none;
        letter-spacing: 0.1rem;
    }

    .card-input__input:hover,
    .card-input__input:focus {
        border-color: #3d9cff;
    }

    .card-input__input:focus {
        box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
    }

    .card-input__input.-select {
        -webkit-appearance: none;
        background-image: url('data:image/png; base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC');
        background-size: 12px;
        background-position: 90% center;
        background-repeat: no-repeat;
        padding-right: 30px;
    }

    .slide-fade-up-enter-active {
        transition: all 0.25s ease-in-out;
        transition-delay: 0.1s;
        position: relative;
    }

    .slide-fade-up-leave-active {
        transition: all 0.25s ease-in-out;
        position: absolute;
    }

    .slide-fade-up-enter {
        opacity: 0;
        transform: translateY(15px);
        pointer-events: none;
    }

    .slide-fade-up-leave-to {
        opacity: 0;
        transform: translateY(-15px);
        pointer-events: none;
    }

    .slide-fade-right-enter-active {
        transition: all 0.25s ease-in-out;
        transition-delay: 0.1s;
        position: relative;
    }

    .slide-fade-right-leave-active {
        transition: all 0.25s ease-in-out;
        position: absolute;
    }

    .slide-fade-right-enter {
        opacity: 0;
        transform: translateX(10px) rotate(45deg);
        pointer-events: none;
    }

    .slide-fade-right-leave-to {
        opacity: 0;
        transform: translateX(-10px) rotate(45deg);
        pointer-events: none;
    }

    .github-btn {
        position: absolute;
        right: 40px;
        bottom: 50px;
        text-decoration: none;
        padding: 15px 25px;
        border-radius: 4px;
        box-shadow: 0px 4px 30px -6px rgba(36, 52, 70, 0.65);
        background: #24292e;
        color: #fff;
        font-weight: bold;
        letter-spacing: 1px;
        font-size: 16px;
        text-align: center;
        transition: all 0.3s ease-in-out;
    }

    @media screen and (min-width: 500px) {
        .github-btn:hover {
            transform: scale(1.1);
            box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
        }
    }

    @media screen and (max-width: 700px) {
        .github-btn {
            position: relative;
            bottom: auto;
            right: auto;
            margin-top: 20px;
        }

        .github-btn:active {
            transform: scale(1.1);
            box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
        }
    }

    .credit_card_details_row {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: 0px;
        margin-left: 0px;
        justify-content: space-between;
        max-height: 70px;
        align-items: flex-start;
    }

    .card-item__numberTitle {
        display: block;
        font-size: 13px;
        padding-bottom: 0px;
        width: 100%;
        margin-bottom: 0;
    }

    .input_blanks_con {
        position: absolute;
        bottom: 7px;
        left: 6px;
        width: 100%;
        z-index: -1;
    }
    /*
.input_blanks_con span {
    font-size: 0.85rem;
    padding: 0px 2px;
    font-family: monospace;
}
*/

    .input_blanks_con div {
        width: 0.525rem;
        display: inline-block;
        margin-right: 0.195rem;
        margin-bottom: -3px;
    }

    .card_line {
        border-bottom: 1px solid;
    }

    input#cardCvv {
        background: white;
        height: 27px;
    }

    .card-input_expire_date {
        display: flex;
        justify-content: space-between;
        border-radius: 5px;
        margin-top: 3px;
    }

    .card-input_expire_date .card-input input {
        height: 27px;
    }

    .card-input_expire_date .card-input {
        margin-bottom: 0;
    }
    .card-item__info .card-input input {
        text-transform: capitalize;
        font-size: 16px;
        margin-bottom: 0;
        width: 100%;
        border-radius: 5px;
        max-height: 27px;
        width: 188px;
    }
    [dir='rtl'] .card-item__info .card-input input {
        font-size: 14px;
    }

    .cc_error_list_con {
        padding: 5px 15px;
        z-index: 1;
        position: absolute;
        background-color: #ffeded;
        border-radius: 15px;
        color: white;
        left: 15px;
        right: 15px;
        font-size: 15px;
        box-shadow: 0px 3px 4px rgb(0 0 0 / 20%);
        margin: auto;
        opacity: 0;
        transition: all 0.2s ease-in;
        height: 60px;
    }

    .cc_error_list_con span {
        font-size: 0.8rem;
        left: 10px;
        color: #aa0000;
    }

    .cc_error_list_con ul {
        padding-left: 15px;
    }

    .card-item__wrapper.white_txt input::placeholder {
        color: white;
    }

    /*
.cc_error_list_con::after {
    position: absolute;
    transform: rotate( 45deg );
    content: "";
    top: -10px;
    background-color: #FF1E5A;
    width: 20px;
    height: 20px;
    margin: auto;
    left: 0;
    right: 0;
}
*/
    .card-form__button.-active {
        background-color: #ffcd00;
        color: #000000;
    }

    button.card-form__button:focus {
        outline: none;
    }

    #payment_wrap {
        padding: 6px 20px;
    }

    .card-item__content .card-input {
        margin-bottom: 0px;
        margin-right: 10px;
    }

    /* input#cardNumber {
        font-family: 'Roboto Mono', monospace !important;
    } */

    .card-form__button {
        width: 315px;
        height: 50px;
        background: #e0e0e0;
        border: none;
        border-radius: 30px;
        font-size: 1.15rem;
        font-weight: 500;

        color: #fff;
        margin-top: 20px;
        text-transform: uppercase;
        cursor: pointer;
    }

    @media screen and (max-width: 480px) {
        .card-form__inner {
            padding: 15px;
            padding-top: 130px;
            padding-bottom: 0;
        }
    }

    @media screen and (max-width: 359px) {
        .card-form__inner {
            padding: 15px 0 0px;
            padding-top: 130px;
        }

        .card-list {
            min-width: 300px;
        }

        .card-form__button {
            width: 290px;
        }
    }
</style>
