import QrView from '../views/LandingPage/QrView.vue';
import Rating from '../views/LandingPage/RatingView.vue';
import Error from '../views/LandingPage/ErrorView.vue';
import SearchView from '../views/Scan2Pay/SearchView.vue';
import PaymentDetailsView from '../views/Scan2Pay/PaymentDetailsView.vue';
import CreditCardPaymentView from '../views/Scan2Pay/CreditCardPaymentView.vue';
import ReceiptDetailsView from '../views/Scan2Pay/ReceiptView.vue';
import NoPaymentRequiredView from '../views/Scan2Pay/NoPaymentRequiredView.vue';
import ErrorView from '../views/Scan2Pay/ErrorView.vue';
import main from '../views/Scan2Pay/main.vue';
import mainLandingPage from '../views/LandingPage/main.vue';

export default [
    {
        path: '/',
        name: 'mainLandingPage',
        component: mainLandingPage,
        children: [
            {
                path: 'qr/:tranId',
                name: 'Home',
                component: QrView,
                caseSensitive: false,
            },
            {
                path: '/rating',
                name: 'Rating',
                component: Rating,
                caseSensitive: false,
            },
            {
                path: '/error/:errorCode',
                name: 'Error',
                component: Error,
                caseSensitive: false,
            },
            // {
            //     path: '/about',
            //     name: 'About',
            //     // route level code-splitting
            //     // this generates a separate chunk (about.[hash].js) for this route
            //     // which is lazy-loaded when the route is visited.
            //     component: () =>
            //         import(/* webpackChunkName: "about" */ '../views/LandingPage/AboutView.vue'),
            // },
        ],
    },

    {
        path: '/static/:staticQr',
        name: 'main',
        component: main,
        children: [
            {
                path: '',
                name: 'Search',
                component: SearchView,
            },
            {
                path: 'paymentDetails',
                name: 'PaymentDetails',
                component: PaymentDetailsView,
                // meta: {
                //     hideSecurityIcons: true,
                // },
            },
            {
                path: 'creditCardPayment',
                name: 'CreditCardPayment',
                component: CreditCardPaymentView,
            },
            {
              path: 'receipt/:transaction',
                name: 'Receipt',
                component: ReceiptDetailsView,
            },
            {
                path: 'NoPayment',
                name: 'NoPayment',
                component: NoPaymentRequiredView,
            },
            {
                path: 'error/:type',
                name: 'error',
                component: ErrorView,
            },
        ],
    },
];
