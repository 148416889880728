import { defineStore } from 'pinia';
import { loadScript } from 'vue-plugin-load-script';
import { PaymentMethodTypes } from '@/typings/paymentMethods';

interface LanguageInfo {
    rtl: boolean;
    language: string;
}

interface MainStoreState {
    currentLanguage: null | string;
    loading: boolean;
    loaderStyle: null | Common.Dictionary<string, string>;
    isReady: boolean;
    scripts: { [key in PaymentMethodTypes]?: boolean | null };
    rtl: boolean;
}
interface MainStoreActions {
    loadInitial: () => void;
    setLoading: (_a: boolean, style?: Common.Dictionary<string, string>) => void;
    setIsReady: (_a: boolean) => void;
    loadPayments: () => void;
    setLanguage: (_a: LanguageInfo) => void;
}

export default defineStore<'mainStore', MainStoreState, {}, MainStoreActions>('mainStore', {
    state: () => ({
        currentLanguage: 'eng',
        configuration: null,
        loading: true,
        rtl: false,
        isReady: false,
        loaderStyle: null,
        scripts: {
            [PaymentMethodTypes.GOOGLE_PAYMENT_METHOD]: null,
            [PaymentMethodTypes.APPLE_PAYMENT_METHOD]: null,
        },
    }),
    actions: {
        loadInitial() {
            // this.setLoading(true);
            // await this.loadConfiguration();
            this.loadPayments();
            // this.setLoading(false);
        },
        setLoading(loading: boolean, style?: Common.Dictionary<string, string>) {
            this.loading = loading;
            if (loading) this.loaderStyle = style;
        },
        setIsReady(isReady: boolean) {
            this.isReady = isReady;
        },
        loadPayments() {
            const self = this;

            loadScript('https://pay.google.com/gp/p/js/pay.js')
                .then(() => {
                    self.scripts = {
                        ...self.scripts,
                        [PaymentMethodTypes.GOOGLE_PAYMENT_METHOD]: true,
                    };
                })
                .catch(() => {
                    self.scripts = {
                        ...self.scripts,
                        [PaymentMethodTypes.GOOGLE_PAYMENT_METHOD]: false,
                    };
                    console.warn('Google pay was not loaded');
                });

            loadScript('https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js')
                .then(() => {
                    self.scripts = {
                        ...self.scripts,
                        [PaymentMethodTypes.APPLE_PAYMENT_METHOD]: true,
                    };
                })
                .catch(() => {
                    self.scripts = {
                        ...self.scripts,
                        [PaymentMethodTypes.APPLE_PAYMENT_METHOD]: false,
                    };
                    console.warn('Apple pay was not loaded');
                });
        },
        setLanguage({ rtl, language }) {
            this.currentLanguage = language;
            this.rtl = rtl;
            const html = document.querySelector('html');
            if (html) {
                html.setAttribute('dir', rtl ? 'rtl' : 'ltr');
                if (language) html.setAttribute('lang', language.substring(0, 2));
            }
        },
    },
    // getters,
    // other options...
});
