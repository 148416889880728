<script setup>
    import LoadingOverlay from './components/common/LoadingOverlay.vue';
    import useStore from './store';
    const store = useStore();
    store.loadInitial();
</script>
<template>
    <div class="main-wrapper" id="nav">
        <router-view />
        <LoadingOverlay v-if="store.loading" :style="store.loaderStyle" />
    </div>
</template>
<style>
    body {
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        --blue: #007bff;
        --indigo: #6610f2;
        --purple: #6f42c1;
        --pink: #e83e8c;
        --red: #dc3545;
        --orange: #fd7e14;
        --yellow: #ffc107;
        --green: #28a745;
        --teal: #20c997;
        --cyan: #17a2b8;
        --white: #fff;
        --gray: #6c757d;
        --gray-dark: #343a40;
        --primary: #007bff;
        --secondary: #6c757d;
        --success: #28a745;
        --info: #17a2b8;
        --warning: #ffc107;
        --danger: #dc3545;
        --light: #f8f9fa;
        --dark: #343a40;
        --breakpoint-xs: 0;
        --breakpoint-sm: 576px;
        --breakpoint-md: 768px;
        --breakpoint-lg: 992px;
        --breakpoint-xl: 1200px;
        --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
            'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
            'Segoe UI Symbol', 'Noto Color Emoji';
        --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
            'Courier New', monospace;
        box-sizing: border-box;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
            'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
            'Noto Color Emoji';
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        text-align: left;
        background: #262626;
        min-height: 100vh;
        position: relative;
        height: 100vh;
        margin: 0;
    }
    body button:focus-visible {
        outline: -webkit-focus-ring-color auto 1px;
    }
    #app {
        box-sizing: border-box;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        min-height: 100%;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        font-family: sans-serif !important;
    }
    a:not([href]) {
        color: inherit;
        text-decoration: none;
    }
    h5,
    a {
        font-size: 0.65rem;
        text-transform: capitalize;
        text-rendering: geometricprecision;
        display: inline-block;
        margin-bottom: 0rem;
        line-height: 0.65rem;
    }
    a {
        color: #007bff;
        text-decoration: none;
        background-color: transparent;
    }
    img {
        vertical-align: middle;
        border-style: none;
    }

    .inner-wrapper {
        text-align: left;
        background-color: #fff;
    }
</style>
<style scoped></style>
