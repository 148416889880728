<template>
    <div class="rating-view">
        <ErroMessage
            v-if="apiErrors.isError"
            :is-error="apiErrors.isError"
            :err-code="apiErrors.errCode"
            :is-client-err="apiErrors.isClientErr"
        />
        <div class="inner-wrapper" v-show="!apiErrors.isError">
            <!--payment succesful section-->
            <!--rating section-->
            <div id="rating_wrap" v-show="ratingModel.isFeedbackSent === false">
                <Row>
                    <div class="section_header col center">
                        <h3 class="black_txt line_break">
                            {{ $t('How would you rate') }}
                        </h3>
                        <h3 class="black_txt">
                            {{ $t('your experience?') }}
                        </h3>
                    </div>
                </Row>
                <div class="row" @click.capture="nullifyStar">
                    <div class="rating_stars_wrap col">
                        <div class="rating_stars">
                            <RatingStar
                                v-for="index in 5"
                                :key="index"
                                :index="index"
                                :is-active="ratingModel.starIndex >= index"
                                @get-star-index="getStarIndex"
                            ></RatingStar>
                        </div>
                    </div>
                </div>
                <Row>
                    <div class="feedback_wrap col">
                        <label
                            for="feedback"
                            :style="{
                                visibility: !ratingModel.feedback ? 'visible' : 'hidden',
                            }"
                            v-if="isFeedback"
                        >
                            {{ $t('Share details of your experience with the machine') }}
                        </label>
                        <textarea
                            ref="feedback"
                            v-model="ratingModel.feedback"
                            id="feedback"
                            type="text"
                            autocomplete="off"
                            placeholder=" "
                            v-on:keydown="checkTextAreaKeyDown"
                            v-if="isFeedback"
                            class="feedback_input"
                            maxlength="75"
                        ></textarea>
                    </div>
                </Row>
            </div>

            <!-- share wrap -->
            <div class="share_wrap col" style="margin-bottom: 30px" id="share_button_wrap">
                <Row>
                    <button
                        @click="sendFeedback"
                        class="card-form__button"
                        :class="{ active_button: isFeedback }"
                        v-show="
                            ratingModel.isFeedbackSent === false &&
                            isFeedback &&
                            ratingModel.shareFeedbackClicked === false
                        "
                    >
                        {{ $t('Share feedback') }}
                    </button>
                    <button
                        class="card-form__button"
                        :class="{ active_button: isFeedback }"
                        v-show="
                            ratingModel.shareFeedbackClicked && ratingModel.isFeedbackSent === false
                        "
                    >
                        <img
                            src="//static.nayax.com/machine_Landing_page_asstes/success4.gif"
                            style="height: 40px"
                        />
                    </button>
                </Row>
            </div>

            <!--Ereceipt-->
            <SuccessOverlay
                class="success_pay_con"
                v-if="isEmailSuccess"
                img="//static.nayax.com/machine_Landing_page_asstes/sending.gif"
            />
            <div class="inner-wrapper" :key="componentKey">
                <!--ereceipt section-->
                <div id="ereceipt_wrap">
                    <Row>
                        <div class="section_header col center">
                            <h3 class="black_txt bold line_break">
                                {{ $t('Payment Receipt') }}
                            </h3>
                        </div>
                    </Row>
                    <div class="row" @click.capture="cancelEmailFlow">
                        <div class="ereceipt_table_wrap col">
                            <table id="ereceipt_table">
                                <tr class="machine_type_image_row">
                                    <td>
                                        <div class="machine_type_image">
                                            <img :src="ereceipt.MachineTypeLutImage" />
                                        </div>
                                    </td>
                                    <td>
                                        {{ ereceipt.MachineId }} |
                                        {{ ereceipt.MachineName }}
                                    </td>
                                </tr>
                                <tr class="payment_type_image_row">
                                    <td>
                                        <div class="payment_type_image">
                                            <img v-if="paymentTypePic" :src="paymentTypePic" />
                                        </div>
                                    </td>
                                    <td>
                                        {{ paymentTypeLabel }} |
                                        <span v-for="index in 4" :key="index" class="cardBullet">
                                            <svg height="4" width="4">
                                                <circle cx="2" cy="2" r="2" fill="black" />
                                            </svg>
                                        </span>
                                        {{ ereceipt.CardLast4Digits }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {{ $t('transaction id') }}
                                    </td>
                                    <td>#{{ ereceipt.EngTransactionId }}</td>
                                </tr>
                                <tr>
                                    <td>{{ $('date/Time') }}</td>
                                    <td>{{ paymentTimeUI }}</td>
                                </tr>
                                <!--
                <tr>
                    <td>{{ereceiptText.table.vatLabel}}</td>
                    <td>{{ereceipt.CurrencySign}}{{ereceipt.VAT | parseDecimal}}</td>
                </tr>
                    -->
                                <tr class="total_price_row">
                                    <td>{{ $t('total price') }}</td>
                                    <td>
                                        <h3 class="black_txt bold">
                                            {{ ereceipt.CurrencySign
                                            }}{{ parseDecimal(ereceipt.ChargeAmount) }}
                                        </h3>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <!-- ereceipt handlers wrap -->
                    <div class="ereceipt_handlers_wrap col">
                        <Row>
                            <div class="button_group">
                                <button
                                    :class="{ hideAnimation: isEmailFlow }"
                                    class="card-form__button"
                                    @click="saveEreceipt"
                                >
                                    <img src="../../assets/images/ereceipt/save.svg" />
                                    <span>{{ $t('save') }}</span>
                                </button>
                                <div
                                    class="send_button_container"
                                    :style="{
                                        width: isEmailFlow ? '100%' : '9rem',
                                    }"
                                >
                                    <button
                                        class="card-form__button"
                                        @click="sendEreceiptHandle"
                                        :disabled="isEmailSending"
                                    >
                                        <img src="../../assets/images/ereceipt/send.svg" />
                                        <span>{{ $t('send') }}</span>
                                    </button>
                                    <input
                                        v-model="email"
                                        class="ereceipt_email_input"
                                        :class="{
                                            email_flow_input: isEmailFlow,
                                        }"
                                        ref="ereceipt_email_input"
                                        @keyup="handleEmailKeyPress"
                                        :style="{
                                            borderColor: isValidErr ? '#AA0000' : '#262626',
                                        }"
                                    />
                                    <div
                                        class="err_validation_con"
                                        v-show="isValidErr && isEmailFlow"
                                    >
                                        <span class="err">{{ errors }}</span>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <!--
                <div class="row">
                    <button class=" card-form__button test" @click="window.open('http://nayax-abt-ereceipt-qa.s3.amazonaws.com/2020/05/24/mot/n6IjoDxp0EuhI0hQv06APw2/ereceipt3211.pdf')">test link download</button>
                </div>
            -->
                    </div>
                </div>
            </div>

            <!-- monyx store -->
            <MonyxBanner :storeurl="storeurl" />
        </div>
    </div>
</template>

<script>
    import RatingStar from '../../components/common/RatingStar';
    import ErroMessage from '../../components/common/ErrorMessage';
    import errorMsgCompMixin from '../../mixins/errorMsgCompMixin';
    import landingPageConst from '../../constants';
    import Row from '../../components/common/RowSection.vue';
    import SuccessOverlay from '../../components/common/SuccessOverlay.vue';
    import MonyxBanner from '../../components/Rating/MonyxBanner.vue';
    const paymentSvgs = require.context('../../assets/images/payment', false, /\.svg$/);
    const lang = 'eng';

    export default {
        data: () => ({
            componentKey: 0,
            qrCode: '',
            landingPageConst: landingPageConst,
            ratingModel: {
                feedback: '',
                isFeedbackSent: false,
                starIndex: 0,
                shareFeedbackClicked: false,
            },
            appStoreUrl: 'https://apps.apple.com/us/app/monyx-wallet/id1455956409',
            playStoreUrl: 'https://play.google.com/store/apps/details?id=com.monyx.wallet',
            storeurl: '',
            ereceipt: {
                MachineName: '',
                MachineId: '',
                MachineType: '',
                EngTransactionId: '',
                EngMachineAuTime: '',
                ChargeAmount: '',
                PaymentMethod: '',
                CardBrand: '',
                CardLast4Digits: '',
                CurrencySign: '',
                MachineTypeLutImage: '',
                VAT: '',
            },
            ereceiptFileModel: {
                fileName: '',
                fileUrl: '',
            },
            isEmailFlow: false,
            isEmailSending: false,
            email: '',
            isValidErr: false,
            isAppErr: false,
            isEmailSuccess: false,
            errors: '',
        }),
        components: { RatingStar, ErroMessage, Row, SuccessOverlay, MonyxBanner },
        mounted: function () {
            var self = this;
            self.$data.storeurl = self.getStoreUrl();
            self.qrCode = this.$route.query.qr;

            self.$http()
                .get('/ereceipt/charge-details?qr=' + self.qrCode)
                .then((res) => {
                    var model = res.data;
                    self.ereceipt.MachineName = model.machineName;
                    self.ereceipt.MachineId = model.machineId;
                    self.ereceipt.MachineType = model.machineType;
                    self.ereceipt.EngTransactionId = model.engTransactionId;
                    self.ereceipt.EngMachineAuTime = model.engMachineAuTime;
                    self.ereceipt.ChargeAmount = model.chargeAmount;
                    self.ereceipt.PaymentMethod = model.paymentMethod;
                    self.ereceipt.CardBrand = model.cardBrand?.replace(' ', '_').toLowerCase();
                    self.ereceipt.CardLast4Digits = model.cardLast4Digits;
                    self.ereceipt.CurrencySign = model.currencySign;
                    self.ereceipt.VAT = model.VAT;
                    self.ereceipt.MachineTypeLutImage = model.machineTypeLutImage;

                    self.componentKey += 1;
                })
                .catch((err) => {
                    self.setClientError(err);
                });
        },
        computed: {
            isFeedback: function () {
                return this.ratingModel.starIndex > 0;
            },
            showRating: function () {
                return this.ratingModel.isFeedbackSent;
            },
            skipToReceiptUrl() {
                return '/ereceipt?qr=' + this.qrCode;
            },
            paymentTimeUI() {
                var self = this;
                if (!self.ereceipt.EngMachineAuTime) return '00/00/00 00:00:00';
                var paymentDate = new Date(self.ereceipt.EngMachineAuTime);
                var setTimeDigits = (timePart) => (timePart < 10 ? '0' : '') + timePart;

                var day = setTimeDigits(paymentDate.getDate());
                var month = setTimeDigits(paymentDate.getMonth() + 1);
                var hour = setTimeDigits(paymentDate.getHours());
                var minute = setTimeDigits(paymentDate.getMinutes());

                var paymentDateUI =
                    (lang === 'eng' ? day + '/' + month : month + '/' + day) +
                    '/' +
                    paymentDate.getFullYear();
                var paymentTimeUI = hour + ':' + minute + ':' + paymentDate.getSeconds();

                return paymentTimeUI + ' ' + paymentDateUI;
            },
            paymentTypePic() {
                if (!this.ereceipt.PaymentMethod) return;
                var svgPath = '';
                if (this.ereceipt.PaymentMethod === landingPageConst.paymentType.creditCard) {
                    svgPath = this.ereceipt.CardBrand;
                } else {
                    svgPath = Object.keys(landingPageConst.paymentType).find(
                        (pt) => landingPageConst.paymentType[pt] === this.ereceipt.PaymentMethod
                    );
                }
                if (!svgPath) return '';
                return paymentSvgs(`./${svgPath}.svg`);
            },
            paymentTypeLabel() {
                var self = this;
                var paymentTypesKeys = Object.keys(landingPageConst.paymentType);
                var paymentMethodName = paymentTypesKeys.find(
                    (pt) => landingPageConst.paymentType[pt] === self.ereceipt.PaymentMethod
                );
                var paymentMethodVal = self.$t(paymentMethodName);
                return paymentMethodVal;
            },
            cardNumberPresent() {
                return '&#183;&#183;&#183;&#183;' + this.ereceipt.CardLast4Digits;
            },
        },
        watch: {
            isAppErr(val) {
                if (!val) return;

                var self = this;
                setTimeout(() => {
                    self.isAppErr = false;
                }, 2000);
            },
            /*
        isEmailFlow(val) {
            if (val)
        }
        */
        },
        mixins: [errorMsgCompMixin],
        methods: {
            parseDecimal(value) {
                if (!value) return;
                return value.toFixed(2);
            },
            getStarIndex: function (starIndex) {
                this.ratingModel.starIndex = starIndex;
                /* this.$refs.feedback.focus(); */
            },
            nullifyStar: function () {
                this.ratingModel.starIndex = 0;
            },
            sendFeedback: function () {
                var self = this;
                if (!self.isFeedback) return;

                self.ratingModel.feedback = self.ratingModel.feedback
                    ? self.ratingModel.feedback.replace(/[^a-z0-9áéíóúñü .,_-]/gim, '').trim()
                    : '';

                var requestModel = {
                    feedback: self.ratingModel.feedback,
                    starIndex: self.ratingModel.starIndex,
                    QR: self.qrCode,
                };

                self.$http()
                    .post('/rating/rating', requestModel)
                    .then(() => {
                        self.ratingModel.shareFeedbackClicked = true;
                        setTimeout(() => {
                            self.ratingModel.isFeedbackSent = true;
                        }, 3000);
                    })
                    .catch((err) => {
                        self.setClientError(err);
                    });
            },
            checkTextAreaKeyDown: function (event) {
                if (event.keyCode === 13) event.preventDefault();
            },
            getMobileOperatingSystem: function () {
                var userAgent = navigator.userAgent || navigator.vendor || window.opera;

                // Windows Phone must come first because its UA also contains "Android"
                if (/windows phone/i.test(userAgent)) {
                    return 'Windows Phone';
                }

                if (/android/i.test(userAgent)) {
                    return 'Android';
                }

                // iOS detection
                if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                    return 'iOS';
                }
                return 'unknown';
            },
            getStoreUrl: function () {
                var self = this;

                var os = self.getMobileOperatingSystem();
                if (os === 'Android') {
                    return self.$data.playStoreUrl;
                } else if (os === 'iOS') {
                    return self.$data.appStoreUrl;
                } else if (os === 'Windows Phone') {
                    return '';
                } else {
                    return '';
                }
            },
            errBackClick() {
                this.apiErrors = {
                    isError: false,
                    errCode: -1,
                    isClientErr: false,
                };
                this.cancelEmailFlow();
            },
            cancelEmailFlow() {
                this.isEmailFlow = false;
            },
            downloadFile(fileName, url) {
                var link = document.createElement('a');
                link.download = fileName;
                link.href = url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            handleEmailKeyPress() {
                // if (!this.isValidErr || !this.handleValidateEmail()) return
            },
            validateEmail(emailAddress) {
                emailAddress = typeof emailAddress === 'object' ? emailAddress.val() : emailAddress;
                var localPartRgx = /^(([^<>()[\]\\.,;:\s@"']+(\.[^<>()[\]\\.,;:\s@"']+)*)|(".+"))@/;
                var domainRgx =
                    /((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                var rgex = new RegExp(localPartRgx.source + domainRgx.source);
                return rgex.test(emailAddress);
            },
            handleValidateEmail() {
                var self = this;
                var result = self.validateEmail(self.email);
                if (!result) {
                    self.errors = self.$t('Please enter a valid Email');
                    self.isValidErr = true;
                } else {
                    self.errors = '';
                    self.isValidErr = false;
                }
                return result;
            },
            sendEreceiptHandle() {
                var self = this;
                if (!self.isEmailFlow) {
                    self.isEmailFlow = true;

                    setTimeout(() => {
                        self.$refs.ereceipt_email_input.focus();
                    }, 500);
                } else if (self.isEmailFlow) {
                    if (!self.handleValidateEmail()) return;

                    self.isEmailSending = true;
                    this.$http()
                        .post('/ereceipt/email?qr=' + self.qrCode + '&email=' + self.email)
                        .then(() => {
                            self.isEmailSuccess = true;

                            setTimeout(() => {
                                self.isEmailSuccess = false;
                                self.isEmailSending = false;
                            }, 3000);
                        })
                        .catch((ex) => {
                            self.isAppErr = !!ex;
                            self.setClientError(ex);
                            self.isEmailSuccess = false;
                            self.isEmailSending = false;
                        });
                }
            },
            saveEreceipt() {
                var self = this;
                if (self.ereceiptFileModel.fileUrl) {
                    self.downloadFile(
                        self.ereceiptFileModel.fileName,
                        '/ereceipt/file?qr=' +
                            self.qrCode +
                            '&url=' +
                            self.ereceiptFileModel.fileUrl
                    );
                } else {
                    self.$http()
                        .post('/ereceipt/generate?qr=' + self.qrCode)
                        .then((res) => {
                            self.ereceiptFileModel.fileName = res.data.receiptFileName;
                            self.ereceiptFileModel.fileUrl = res.data.receiptURL;

                            /*
                self.downloadFile(self.ereceiptFileModel.fileName, '/ereceipt/file?qr=' + self.qrCode
                                                                    + '&url=' + self.ereceiptFileModel.fileUrl);
                */

                            window.open(self.ereceiptFileModel.fileUrl);
                        })
                        .catch((ex) => {
                            self.isAppErr = !!ex;
                            self.setClientError(ex);
                        });
                }
            },
        },
    };
</script>

<style scoped>
    label[for='feedback'] {
        position: absolute;
        top: 5px;
        padding: 3px;
        color: #979797;
        margin-left: 20px;
        margin-right: 20px;
        font-size: 0.9rem;
    }

    #feedback {
        width: 100%;
        border: 0px solid #000;
        border-bottom: 1px solid #eeeeee;
        resize: none;
        max-height: 90px;
        padding: 8px 22px;
        font-size: 0.9rem;
        outline-color: #ffcd00;
        height: 60px;
    }

    #feedback:focus-visible {
        outline-color: #ffcd00;
    }

    .share_wrap.col {
        margin-top: 15px;
    }

    .share_wrap.col .row {
        justify-content: center;
    }

    .share_wrap button {
        margin-top: 0;
        margin-bottom: 30px;
        height: 50px;
        max-width: 315px;
    }

    .share_wrap a.redirectLink {
        text-decoration: none;
        color: #007aff;
        font-weight: 600;
        font-size: 14px;
        line-height: 1rem;
    }
    .feedback_wrap {
        justify-content: center;
        margin: 15px 0 0px;
        display: flex;
    }
    .feedback_wrap.col {
        margin-top: 20px;
        position: relative;
    }

    #rating_wrap .section_header {
        padding: 40px 60px 15px;
    }

    #rating_wrap .section_header h4 {
        line-height: 1.2rem;
        font-size: 0.8rem;
        font-weight: 500;
    }

    #rating_wrap .rating_stars .oi-star {
        font-size: 1.35rem;
    }

    #rating_wrap span.oi.oi-star:after {
        content: '\e0c2';
        position: absolute;
        color: white;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        font-size: 0.7rem;
        margin: auto;
        display: block;
        text-align: center;
        line-height: 1.35rem;
    }

    #rating_wrap .rating_stars .oi-star.star_active {
        color: #ffd800;
    }

    #rating_wrap span.oi.oi-star.star_active:after {
        display: none;
    }

    .rating_stars_wrap {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-content: center;
        width: 100%;
    }

    .ereceipt_handlers_wrap .button_group img {
        width: 1.2rem;
    }

    .ereceipt_handlers_wrap .button_group button,
    .white_button {
        background-color: white;
        border: 1px solid #262626;
        color: #262626;
        text-transform: capitalize;
        font-size: 0.9rem;
        width: 9rem;
        transition: all 0.5s ease-in;
        opacity: 1;
    }
    .ereceipt_handlers_wrap .button_group button:focus {
        outline: none;
    }

    .ereceipt_handlers_wrap.col {
        padding-bottom: 55px;
    }

    .ereceipt_email_input {
        background-color: #f7f7f7;
        font-size: 0.9rem;
        border: 1px solid #262626;
        color: #262626;
        width: 5rem;
        position: absolute;
        transition: all 0.3s ease-in;
        right: 0;
        bottom: 0;
        height: 50px;
        top: 0;
        border-radius: 440px;
        z-index: -1;
        visibility: hidden;
        outline: none;
    }

    .ereceipt_email_input.email_flow_input {
        width: 100%;
        visibility: visible;
        z-index: 1;
        padding: 18px;
    }

    .send_button_container {
        position: relative;
        height: 40px;
        width: 9rem;
        margin-top: 20px;
        float: right;
    }

    .err_validation_con {
        width: 100%;
        height: 60px;
        position: absolute;
        top: 1rem;
        background-color: #ffeded;
        z-index: 0;
        border-radius: 0 0 15px 15px;
    }
    .ereceipt_handlers_wrap .button_group .hideAnimation {
        animation-name: hide-button;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }
    .ereceipt_handlers_wrap .button_group .send_button_container button {
        margin-top: 0;
        right: 0;
        z-index: 2;
        position: absolute;
    }

    .send_button_container span.err {
        position: absolute;
        bottom: 0.25rem;
        font-size: 0.8rem;
        left: 10px;
        color: #aa0000;
    }
    table#ereceipt_table {
        width: 100%;
    }

    table#ereceipt_table td {
        width: 50%;
        padding: 15px 0;
    }

    table#ereceipt_table td:first-child {
        text-align: left;
        color: #606468;
        text-transform: capitalize;
        width: 30%;
    }

    table#ereceipt_table td:last-child {
        text-align: right;
        width: 70%;
        font-weight: 500;
    }

    table#ereceipt_table tr {
        border-top: 1px solid #dedede;
    }

    table#ereceipt_table tr:last-child {
        border-bottom: 1px solid #dedede;
    }

    table#ereceipt_table .total_price_row h3 {
        font-weight: 600;
        font-size: 18px;
    }

    table#ereceipt_table tr:first-child td:first-child {
        width: 20%;
    }

    table#ereceipt_table tr:first-child td:last-child {
        width: 80%;
    }

    .ereceipt_table_wrap.col {
        font-size: 0.8rem;
        padding: 0 30px;
    }

    table#ereceipt_table tr.machine_type_image_row td,
    table#ereceipt_table tr.payment_type_image_row td {
        padding: 5px 0;
    }

    table#ereceipt_table tr.total_price_row td {
        padding: 25px 0 5px 0;
    }
</style>
