import { TransactionInfoApiModel } from '@/typings/apiClient/common';
import { Scan2PayInitialData, TicketFeeResponseModel } from '@/typings/apiClient/scan2Pay';
import { PaymentMethodProviders } from '@/typings/paymentMethods';
import axios from 'axios';
import config from '../config/app';
import { isObjKey } from './tsUtils';

let baseUrl = config.serverURI.production;
const envName = process.env.NODE_ENV;

if (envName && isObjKey(envName, config.serverURI)) {
    baseUrl = config.serverURI[envName];
} else {
    console.debug('Couldnt identiy environment name. Using: ' + envName);
}

export const axiosClient = axios.create({
    baseURL: baseUrl,
});

const apple = {
    validateMerchant: (appleUrl: string, paymentName: string) =>
        axiosClient.post('/apple/validate-merchant', { appleUrl, paymentName }),
};

const LandingPage = {
    creditCard: (json: any) => axiosClient.post('/landing-page/cc-pay', json),
    apm: (json: any, paymentMethod: PaymentMethodProviders) =>
        axiosClient.post(`/landing-page/apm/${paymentMethod}`, json),
    visit: (qrCode: string) =>
        axiosClient.post<TransactionInfoApiModel>('/landing-page/visit?qr=' + qrCode),
};

const Scan2Pay = {
    getTicketFeeDetails: (ticketFeeRequestModel: any) =>
        axiosClient.post<TicketFeeResponseModel>('/scan2pay/ticket-fee', ticketFeeRequestModel),
    getInitialData: (staticQr: any) =>
        axiosClient.post<Scan2PayInitialData>('/scan2pay/initial-data/' + staticQr),
    apm: (json: any, paymentMethod: PaymentMethodProviders) =>
        axiosClient.post(`/scan2pay/apm/${paymentMethod}`, json),
    sendEreceipt: (token: string, email: string) =>
        axiosClient.post(
            `/scan2pay/send-ereceipt?token=${encodeURIComponent(token)}` +
                (email ? `&email=${encodeURIComponent(email)}` : '')
        ),
    chargeDetails: (token: string) =>
      axiosClient.get(`/scan2pay/charge-details?token=${encodeURIComponent(token)}`),

    transactionByTicket: (ticketNumber: string, machineId: number) =>
    axiosClient.get(`/scan2pay/transaction-by-ticket?ticketNumber=${encodeURIComponent(ticketNumber)}` +
      (machineId ? `&machineId=${encodeURIComponent(machineId)}` : '')),
};

export default {
    Scan2Pay,
    LandingPage,
    apple,
};
