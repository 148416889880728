import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { axiosClient } from './utils/apiClient';
import 'bootstrap/dist/css/bootstrap.css';
import 'open-iconic/font/css/open-iconic-bootstrap.css';
import './style/style.css';
import LoadScript from 'vue-plugin-load-script';
import Translation from './plugins/translation';
import Maska from 'maska';
import { createPinia } from 'pinia';
import useStore from './store';
import { markRaw } from '@vue/reactivity';

const app = createApp(App);

app.config.globalProperties.$http = () => axiosClient;

const pinia = createPinia();

pinia.use(({ store }) => {
    store.$router = markRaw(router);
});

app.use(Maska);
app.use(LoadScript);
app.use(pinia);
app.use(router);
app.use(Translation, {
    store: useStore(),
});
app.provide('http', axiosClient);
app.mount('#app');
